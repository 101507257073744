@charset "UTF-8";

.scale {
  &__img {
    overflow: hidden;
  }
  img {
    transition: all .4s;
    width: 100%;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}