@charset "UTF-8";

.block-wrap {
  padding: 64px 0;
  background: linear-gradient(to right, $white 0, $white 20%, $gray 20%, $gray 100%);
  @include sp-screen {
    padding: 32px 0;
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  @include sp-screen {
    flex-direction: column;
    &--reverse {
      flex-direction: column-reverse;
    }
  }
  &__text {
    width: 40%;
    padding: 0 6%;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1.6;
    @include pc-screen {
      min-width: 460px;
    }
    @include sp-screen {
      width: 100%;
      padding: 32px 6%;
    }
    p span {
      font-weight: bold;
    }
    &--bg {
      padding: 64px 6%;
      @include sp-screen {
        padding: 32px 6%;
      }
    }
    &--wide {
      width: 46%;
      max-width: 460px;
      padding: 0 2%;
      p {
        min-height: 8.4rem;
        @include sp-screen {
          min-height: auto;
        }
      }
    }
    &--widefull {
      width: 55%;
      max-width: 100%;
    }
    small {
      display: block;
      margin-top: 1em;
      color: $d-gray;
      font-size: 12px;
      line-height: 1.4;
      &::before {
        content: "*";
      }
    }
  }
  &__img {
    width: 60%;
    @include sp-screen {
      width: 100%;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &--card {
    align-items: flex-start;
  }
}
