@charset "UTF-8";

.training {
  .kv {
    background: url(../img/training/kv.jpg) no-repeat center;
    background-size: cover;
    &__ttl {
      line-height: 1.35;
    }
  }
  .sec {
    max-width: 800px;
    margin: 0 auto;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    .numList {
      li {
        display: flex;
        align-items: center;
        margin: 0 0 40px;
        @include sp-screen {
          margin: 0 0 20px;
        }
        &:last-child {
          margin: 0;
        }
        .num {
          color: #52BC56;
          display: inline-block;
          font-family: 'Norwester Regular';
          font-size: 40px;
          padding: 5px 20px 5px 0;
          margin: 0 10px 0 0;
          border-right: 1px solid #707070;
          line-height: .8;
          width: 40px;
          text-align: center;
          @include sp-screen {
            font-size: 30px;
            padding: 5px 20px 5px 0;
            width: 30px;
          }
        }
        span {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          width: calc(100% - 71px);
          @include sp-screen {
            font-size: 12px;
          }
        }
      }
    }
  }
}
