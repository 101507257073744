@charset "UTF-8";

.lesson {
  padding-bottom: 128px;
  .kv {
    background: url(../img/lesson/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .ttl {
    &--target {
      display: flex;
      align-items: center;
      .target {
        position: relative;
        height: 28px;
        margin-left: 24px;
        padding: 0 8px;
        font-size: 12px;
        line-height: 28px;
        background: $primary;
        color: $white;
        &::before {
          content: "";
          position: absolute;
          left: -28px;
          @include triangle-maker(14px, $primary, right);
        }
      }
    }
  }
  .plan {
    margin-top: 32px;
    &__block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 8px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.6;
      &:last-child {
        margin-bottom: 0;
      }
      &--reccomend {
        background: $gray;
      }
    }
    &__name {
      font-weight: bold;
      .tag {
        margin-left: 8px;
        padding: 4px;
        color: #000;
        font-size: 11px;
        font-weight: normal;
        background: $secondary;
      }
    }
    &__price {
      text-align: right;
      font-size: 12px;
      font-weight: bold;
      span {
        font-size: 16px;
      }
      i {
        margin: 0 8px;
      }
      .note {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .block--card {
    .plan {
      min-height: 150px;
    }
  }
  .sec--ticket {
    .block {
      position: relative;
      background: $d-gray;
      color: $white;
      @include sp-screen {
        padding-bottom: 11px;
      }
      &::after {
        content: "";
        display: block;
        width: 22px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        background: url(../img/lesson/bg_ticket.png) repeat-y;
        @include sp-screen {
          width: 100%;
          height: 11px;
          bottom: 0;
          top: initial;
          background: url(../img/lesson/bg_ticket_sp.png) repeat-x;
        }
      }
      small {
        color: $white;
      }
    }
    .plan {
      &__block {
        &:nth-child(odd) {
          background: #888;
        }
      }
    }
    .ttl--sub {
      color: $white;
    }
  }
  .sec--simulation {
    .block__img {
      width: 45%;
      @include sp-screen {
        width: 100%;
      }
    }
  }
}
