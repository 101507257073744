@charset "UTF-8";

.card {
  display: block;
  max-width: 37%;
  color: $black;
  overflow: hidden;
  @include sp-screen {
    width: 100%;
    max-width: 100%;
  }
  &__img {
    margin-bottom: 24px;
  }
  &__ttl {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
    @include sp-screen {
      font-size: 16px;
    }
  }
  &__price {
    margin-bottom: 24px;
    font-size: 12px;
    span {
      font-size: 16px;
    }
    .name {
      margin-right: 1em;
    }
    i {
      margin-right: 8px;
    }
  }
  &__text {
    @include abridgement(1.6,15,2,$white);
  }
  .more {
    margin-top: 16px;
  }
}
