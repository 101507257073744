@charset "UTF-8";

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
  @include sp-screen {
  }
  span,a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin: 0 4px;
    border: solid 1px $black;
    box-sizing: border-box;
    @include norwester;
    &.current,
    &:hover {
      background: $black;
      color: $white;
    }
    &.back {
      width: 126px;
      padding: 0 40px;
    }
    &:first-child,
    &:last-child, {
      margin: 0 20px;
    }
  }
}
