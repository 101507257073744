/* component */
/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0); }

#cboxWrapper {
  max-width: none; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%; }

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left; }

#cboxContent {
  position: relative; }

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#cboxTitle {
  margin: 0; }

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer; }

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic; }

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0; }

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: url(../img/vendor/overlay.png) repeat 0 0;
  opacity: 0.9;
  filter: alpha(opacity=90); }

#colorbox {
  outline: 0; }

#cboxTopLeft {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -101px 0; }

#cboxTopRight {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -130px 0; }

#cboxBottomLeft {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -101px -29px; }

#cboxBottomRight {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -130px -29px; }

#cboxMiddleLeft {
  width: 0;
  background: url(../img/vendor/controls.png) left top repeat-y; }

#cboxMiddleRight {
  width: 0;
  background: url(../img/vendor/controls.png) right top repeat-y; }

#cboxTopCenter {
  height: 0;
  background: url(../img/vendor/border.png) 0 0 repeat-x; }

#cboxBottomCenter {
  height: 0;
  background: url(../img/vendor/border.png) 0 -29px repeat-x; }

#cboxContent {
  background: #fff;
  height:472px !important;
}

.cboxIframe {
  background: #fff; }

#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

#cboxLoadedContent {
  margin-bottom: 0; }

#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494; }

#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 58px;
  color: #949494; }

#cboxLoadingOverlay {
  background: url(../img/vendor/loading_background.png) no-repeat center center; }

#cboxLoadingGraphic {
  background: url(../img/vendor/loading.gif) no-repeat center center; }

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0; }

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef; }

#cboxPrevious {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../img/vendor/controls.png) no-repeat -75px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px; }

#cboxPrevious:hover {
  background-position: -75px -25px; }

#cboxNext {
  position: absolute;
  bottom: 0;
  left: 27px;
  background: url(../img/vendor/controls.png) no-repeat -50px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px; }

#cboxNext:hover {
  background-position: -50px -25px; }

#cboxClose {
  position: absolute;
  top: -40px;
  right: 0;
  background: url(../img/vendor/modal-close.png) no-repeat;
  width: 35px;
  height: 35px;
  text-indent: -9999px; }

#cboxClose:hover {
  opacity: 0.5; }

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF); }
