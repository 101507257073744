.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-logo {
  width: 19.3193359375em;
  @include sp-screen {
    width: 10em;
  }
}

.icon-arrow {
  width: 0.662109375em;
}
