@charset "UTF-8";

.about {
  .kv {
    background: url(../img/about/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .block {
    &__text {
      img {
        width: 100%;
      }
      .info {
        dl {
          display: flex;
          dt {
            width: 9em;
            margin-right: 24px;
            @include sp-screen {
              width: 7em;
              margin-right: 16px;
            }
          }
          dd {
            width: calc(100% - 9em - 24px);
            @include sp-screen {
              width: calc(100% - 7em - 16px);
            }
          }
        }
      }
      .use {
        dt {
          font-size: 16px;
          font-weight: bold;
        }
        a {
          text-decoration: underline;
        }
        iframe {
          margin-top: 16px;
        }
        p {
          margin-bottom: 24px;
        }
      }
      dl {
        margin-bottom: 24px;
        line-height: 1.6;
      }
    }
  }
}
