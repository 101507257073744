@charset "UTF-8";

.news {
  .kv {
    background: url(../img/news/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .block {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .card {
    @include pc-screen {
      max-width: 30%;
      margin-bottom: 2%;
    }
    @include sp-screen {
      margin-bottom: 64px;
    }
    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 260px;
      overflow: hidden;
    }
    &__date {
      margin-bottom: 16px;
      font-size: 12px;
      @include norwester;
    }
  }
  .detail {
    max-width: 800px;
    margin: 0 auto;
    &__block {
      padding-bottom: 64px;
    }
    &__ttl {
      margin-bottom: 64px;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.6;
      @include sp-screen {
        margin-bottom: 32px;
        font-size: 24px;
      }
    }
    &__date {
      margin-bottom: 24px;
      @include norwester;
      font-size: 12px;
    }
    &__text {
      img {
        width: 100%;
        margin-bottom: 32px;
      }
    }
    .wp-block-image {
      margin: 1rem auto;
    }
    a {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }
}
