@charset "UTF-8";

.theory {
  .kv {
    background: url(../img/theory/kv.jpg) no-repeat center;
    background-size: cover;
    &__ttl {
      line-height: 1.35;
    }
  }
  .sec {
    max-width: 800px;
    margin: 0 auto;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    &--theoryList {
      >.contentTtl {
        margin: 0 0 120px;
        @include sp-screen {
          margin: 0 0 60px;
        }
      }
      ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        li {
          width: 31%;
          margin: 0 3.5% 30px 0;
          @include sp-screen {
            width: 48%;
            margin: 0 4% 15px 0;
          }
          &:nth-child(2n) {
            @include sp-screen {
              margin: 0 0 15px;
            }
          }
          &:nth-child(4) {
            .ico {
              img {
                margin: 0 0 0 auto;
              }
            }
          }
          &:nth-child(3n) {
            margin: 0 0 30px;
          }
          &:nth-child(3) {
            @include sp-screen {
              margin: 0 4% 15px 0;
            }
          }
          .ico {
            display: flex;
            align-items: center;
            min-height: 160px;
            margin: 0 0 10px;
            img {
              display: block;
              margin: 0 auto;
            }
          }
          p {
            font-size: 15px;
            line-height: 1.74;
            @include sp-screen {
              font-size: 14px;
            }
          }
        }
      }
    }
    &--theoryReason {
      >.contentTtl {
        margin: 0 0 60px;
        @include sp-screen {
          margin: 0 0 30px;
        }
      }
      > p {
        margin: 0 0 20px;
      }
      .reason {
        display: flex;
        justify-content: space-between;
        margin: 0 0 100px;
        @include sp-screen {
          display: block;
          margin: 0 0 50px;
        }
        li {
          max-width: 380px;
          border: 8px solid #E8EAE8;
          padding: 20px 30px;
          box-sizing: border-box;
          align-items: center;
          @include sp-screen {
            width: 100%;
            padding: 15px 20px;
          }
          &:first-child {
            @include sp-screen {
              margin: 0 0 20px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.75;
            @include sp-screen {
              font-size: 15px;
            }
          }
        }
      }
      .answer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 80px;
        @include sp-screen {
          display: block;
          margin: 0 0 40px;
        }
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:nth-child(1) {
            width: 50%;
            @include sp-screen {
              width: 100%;
              margin: 0 0 20px;
            }
          }
          &:nth-child(2) {
            width: 48%;
            @include sp-screen {
              width: 100%;
            }
          }
          .num {
            color: #52BC56;
            display: inline-block;
            font-family: 'Norwester Regular';
            font-size: 120px;
            padding: 0 30px 0 0;
            margin: 0 10px 0 0;
            border-right: 1px solid #707070;
            line-height: .8;
            @include sp-screen {
              font-size: 80px;
              padding: 0 20px 0 0;
              text-align: center;
              width: 70px;
              box-sizing: border-box;
            }
          }
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;
            @include sp-screen {
              font-size: 15px;
              width: calc(100% - 70px);
            }
          }
        }
      }
    }
  }
}
