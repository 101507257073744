@charset "UTF-8";

.fix-btn {
  position: relative;
  width: 240px;
  height: 230px;
  overflow: hidden;
  z-index: 80;
  position:fixed;
  top: inherit;
  bottom: 0;
  right: 0;
  @include sp-screen {
    width: 210px;
    height: 200px;
  }
  &__inner {
    position: absolute;
    right: -20px;
    bottom: -20px;
    display: block;
    width: 140px;
    height: 140px;
    background: $primary;
    color: $white;
    font-size: 16px;
    text-align: center;
    line-height: 1.6;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    @include sp-screen {
      width: 100px;
      height: 100px;
      font-size: 12px;
    }
    &.active {
      & + .list {
        display: block;
      }
    }
  }
  .text {
    display: inline-block;
    transform: translate(-5px, 20px);
    @include sp-screen {
      transform: translate(-7px, 12px);
    }
  }
  .emphasis {
    font-size: 24px;
    @include sp-screen {
      font-size: 16px;
    }
  }
  .list {
    display: none;
    li {
      a {
        width: 95px;
        height: 30px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        color: $white;
        background-color: $primary;
        border-radius: 15px;
        &.is-disabled {
          pointer-events: none;
          color: $accent;
          background-color: $secondary;
        }
      }
      &:nth-child(1) {
        position: absolute;
        right: 50px;
        bottom: 160px;
        transform: rotate(60deg);
        @include sp-screen {
          right: 22px;
          bottom: 125px;
        }
      }
      &:nth-child(2) {
        position: absolute;
        right: 96px;
        bottom: 128px;
        transform: rotate(45deg);
        @include sp-screen {
          right: 61px;
          bottom: 97px;
          transform: rotate(40deg);
        }
      }
      &:nth-child(3) {
        position: absolute;
        right: 125px;
        bottom: 73px;
        transform: rotate(15deg);
        @include sp-screen {
          right: 87px;
          bottom: 53px;
        }
      }
      &:nth-child(4) {
        position: absolute;
        right: 127px;
        bottom: 20px;
        transform: rotate(0deg);
        @include sp-screen {
          right: 91px;
          bottom: 10px;
        }
      }
    }
  }
}

.footer {
  position: relative;
  width: 100%;
  padding: 68px 0;
  color: $white;
  font-size: 12px;
  background: $black;
  .inner {
    a {
      color: $white;
    }
  }
  .logo-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px;
    @include sp-screen {
      flex-wrap: wrap;
    }
    .logo {
      display: flex;
      @include sp-screen {
        display: block;
        margin-bottom: 32px;
      }
      address {
        margin-left: 16px;
        @include sp-screen {
          margin-top: 16px;
          margin-left: 0;
        }
        span {
          margin-right: 1em;
        }
      }
    }
    .icon-logo {
      @include sp-screen {
        width: 14em;
      }
    }
  }
  .member {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 360px;
    @include sp-screen {
      justify-content: flex-start;
    }
    li {
      margin-right: 48px;
      &:last-child {
        margin-right: 0;
      }
    }
    i {
      margin-right: 8px;
    }
    a {
      display: block;
      color: $white;
    }
  }
  .navi,
  .sub-navi {
    display: flex;
    align-items: center;
    @include sp-screen {
      flex-wrap: wrap;
    }
    li {
      margin: 0 24px 24px 0;
      @include sp-screen {
        width: 100%;
        margin: 0 0 24px;
      }
    }
  }
  .navi {
    @include sp-screen {
      margin-bottom: 16px;
    }
    li {
      font-weight: bold;
    }
  }
  .copy {
    margin-top: 20px;
  }
}

@include sp-screen {}
