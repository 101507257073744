@charset "UTF-8";

.sec--access {
  @include pc-screen {
    width: calc(100% - (9.37vw / 2));
  }
  iframe {
    @include sp-screen {
      width: 100%;
      height: 200px;
    }
  }
  .block {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      right: 0;
      width: 23%;
      height: 350px;
      background: $gray;
      transform: translateY(-50%);
      z-index: 10;
      @include sp-screen {
        display: none;
      }
    }
    &__text {
      position: relative;
      z-index: 20;
    }
  }
}