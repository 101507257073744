@charset "UTF-8";

.facility {
  .kv {
    background: url(../img/facility/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .sec {
    position: relative;
    margin-bottom: 0;
    &.parallax-window {
      min-height: 464px;
      background: transparent;
      @include sp-screen {
        position: sticky;
        top: 0;
      }
    }
    &--01 {
      .block {
        justify-content: flex-end;
      }
    }
    &--02 {
      .block {
        justify-content: flex-start;
      }
    }
    &--03 {
      .block {
        justify-content: flex-end;
      }
    }
    &--04 {
      .block {
        justify-content: flex-start;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.5);
    }
    .block {
      background: none;
      color: #fff;
      z-index: 1;
      position: relative;
    }
    .block__text {
      @include pc-screen {
        width: 52%;
      }
      &--bg {
        @include pc-screen {
          padding: 128px 6%;
        }
        @include sp-screen {
          padding: 128px 0;
        }
      }
    }
    &__ttl {
      font-size: 32px;
      margin-bottom: 32px;
      @include sp-screen {
        font-size: 24px;
      }
      a {
        color: $white;
      }
    }
  }
}
// vendorjs override
.parallax-mirror {
  z-index: 0!important;
}
