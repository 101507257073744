@charset "UTF-8";

.policy {
  .kv {
    background: url(../img/policy/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .box {
    width: 800px;
    margin: 0 auto;
    margin-bottom: 64px;
    @include sp-screen {
      width: 100%;
    }
    &__ttl {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 1.6;
    }
    &__text {
      margin-bottom: 24px;
      .logo {
        margin-bottom: 16px;
        font-size: 12px;
        @include sp-screen {
          font-size: 16px;
        }
      }
      dl {
        line-height: 1.8;
      }
    }
    &__list {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 1.8;
      list-style: decimal;
      padding-left: 1em;
      font-family: "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
      li {
        margin-bottom: 8px;
      }
      ul {
        margin-top: 8px;
        li {
          &::before {
            content: "●";
            width: 1em;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }
}
