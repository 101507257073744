@charset "UTF-8";

.staff {
  .kv {
    background: url(../img/staff/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .sec {
    &--staff {
      .sec__ttl {
        font-size: 32px;
        line-height: 1.4;
        @include sp-screen {
          font-size: 24px;
        }
        span {
          display: block;
          font-size: 18px;
          @include sp-screen {
            font-size: 14px;
          }
        }
      }
      .sec__subttl {
        font-size: 24px;
        @include sp-screen {
          font-size: 18px;
        }
      }
      .block {
        &__img {
          width: auto;
          max-width: 306px;
          @include sp-screen {
            width: auto;
            max-width: 124px;
          }
        }
      }
      .profile {
        margin-bottom: 32px;
        font-size: 16px;
        @include sp-screen {
          font-size: 16px;
        }
        li {
          padding-left: 1em;
          text-indent: -1em;
          &:before {
            content: "・";
            width: 1em;
            height: 1em;
          }
        }
      }
      .message {
        background: $gray;
        padding: 32px 24px;
      }
      &:nth-child(odd) {
        .block {
          &__text {
            @include pc-screen {
              padding-right: 0;
            }
          }
        }
      }
      &:nth-child(even) {
        .block {
          &__text {
            @include pc-screen {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
