@charset "UTF-8";

.putting {
  .kv {
    background: url(../img/putting/kv.jpg) no-repeat center;
    background-size: cover;
    &__ttl {
      line-height: 1.35;
    }
  }
  .sec {
    max-width: 800px;
    margin: 0 auto;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    .numListBox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include sp-screen {
        display: block;
      }
    }
    .numList {
      &--left {
        width: 48%;
        margin: 0 4% 0 0;
        @include sp-screen {
          width: 100%;
          margin: 0 0 10px;
        }
      }
      &--right {
        width: 48%;
        @include sp-screen {
          width: 100%;
        }
      }
      li {
        display: flex;
        align-items: center;
        margin: 0 0 40px;
        @include sp-screen {
          margin: 0 0 20px;
        }
        &:last-child {
          margin: 0;
        }
        .num {
          color: #52BC56;
          display: inline-block;
          font-family: 'Norwester Regular';
          font-size: 40px;
          padding: 5px 20px 5px 0;
          margin: 0 10px 0 0;
          border-right: 1px solid #707070;
          line-height: .8;
          width: 40px;
          text-align: center;
          @include sp-screen {
            font-size: 30px;
            padding: 5px 20px 5px 0;
            width: 30px;
          }
        }
        span {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          width: calc(100% - 71px);
          @include sp-screen {
            font-size: 12px;
          }
        }
      }
    }
    &--textBox {
      .rightBox {
        margin: 0 0 60px;
        .txtBox {
          width: calc(100% - 438px);
          @include sp-screen {
            width: 100%;
            margin: 0 0 20px;
          }
        }
        .imgBox {
          width: 408px;
          @include sp-screen {
            width: 100%;
          }
        }
      }
    }
    &.panelBox {
      max-width: 1204px;
      padding: 0 60px 128px;
      @include sp-screen {
        width: calc(100% - 9.37vw);
        padding: 0 0 64px;
      }
      .panelList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include sp-screen {
          display: block;
        }
        .panelList__item {
          border: 1px solid #707070;
          box-sizing: border-box;
          width: 32%;
          margin: 0 0 40px;
          padding: 24px 24px 130px;
          position: relative;
          @include sp-screen {
            width: 100%;
            padding: 2em 1em;
            margin: 0 0 20px;
          }
          h4 {
            font-size: 24px;
            line-height: 1.7;
            margin: 0 0 40px;
            padding: 0 0 0 20px;
            position: relative;
            min-height: 80px;
            @include sp-screen {
              margin: 0 0 20px;
              padding: 0 0 0 14px;
              font-size: 16px;
              min-height: inherit;
            }
            &:before {
              content: "";
              background: #52BC56;
              display: block;
              width: 4px;
              height: 24px;
              position: absolute;
              left: 0;
              top: 8px;
              @include sp-screen {
                width: 3px;
                height: 16px;
                top: 4px;
              }
            }
          }
          .checkList {
            @include sp-screen {
              margin: 0 0 20px;
            }
            li {
              margin: 0 0 20px;
              @include sp-screen {
                margin: 0 0 10px;
              }
              &:last-child {
                margin: 0;
              }
              p {
                color: #52BC56;
                font-size: 21px;
                font-weight: 600;
                position: relative;
                padding: 0 0 0 36px;
                @include sp-screen {
                  font-size: 16px;
                }
                span {
                  background: linear-gradient(transparent 40%, #FCFC0A 40%);
                }
                &:before {
                  content: "";
                  display: block;
                  border-left: 4px solid #FF5259;
                  border-bottom: 4px solid #FF5259;
                  width: 20px;
                  height: 10px;
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                  left: 0;
                  top: 7px;
                  position: absolute;
                  @include sp-screen {
                    width: 16px;
                    height: 8px;
                    top: 6px;
                  }
                }
              }
            }
          }
          .moreBtn {
            background: #52BC56;
            border-radius: 4px;
            color: #FFF;
            display: block;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            width: 260px;
            height: 64px;
            line-height: 64px;
            position: absolute;
            left: 50%;
            bottom: 24px;
            margin: 0 0 0 -130px;
            @include sp-screen {
              position: relative;
              left: inherit;
              bottom: inherit;
              margin: 0 auto;
              width: 80%;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .modal-mask {
    background: rgba(0,0,0,.6);
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .modal {
    background: #FFF;
    display: none;
    width: 100%;
    max-width: 960px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    @include sp-screen {
      width: 90%;
    }
    &__inner {
      padding: 80px;
      @include sp-screen {
        padding: 2em;
      }
      h3 {
        font-size: 32px;
        line-height: 1.34;
        margin: 0 0 20px;
        @include sp-screen {
          font-size: 20px;
        }
      }
      h4 {
        font-size: 24px;
        line-height: 1.7;
        margin: 0 0 10px;
        padding: 0 0 0 20px;
        position: relative;
        @include sp-screen {
          padding: 0 0 0 14px;
          font-size: 16px;
        }
        &:before {
          content: "";
          background: #52BC56;
          display: block;
          width: 4px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 8px;
          @include sp-screen {
            width: 3px;
            height: 16px;
            top: 4px;
          }
        }
      }
      .checkList {
        margin: 0 0 30px;
        @include sp-screen {
          margin: 0 0 20px;
        }
        li {
          margin: 0;
          @include sp-screen {
            margin: 0 0 5px;
          }
          &:last-child {
            margin: 0;
          }
          p {
            color: #52BC56;
            font-size: 25px;
            font-weight: 600;
            position: relative;
            padding: 0 0 0 36px;
            @include sp-screen {
              font-size: 16px;
            }
            span {
              background: linear-gradient(transparent 40%, #FCFC0A 40%);
            }
            &:before {
              content: "";
              display: block;
              border-left: 4px solid #FF5259;
              border-bottom: 4px solid #FF5259;
              width:20px;
              height:10px;
              -webkit-transform:rotate(-45deg);
              transform:rotate(-45deg);
              left:0;
              top: 10px;
              position: absolute;
              @include sp-screen {
                width: 16px;
                height: 8px;
                top: 6px;
              }
            }
          }
        }
      }
      .imgBox {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            width: 49%;
            margin: 0 2% 0 0;
            &:last-child {
              margin: 0;
            }
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
      > p {
        margin: 0 0 20px;
      }
    }
  }
}
