@charset "UTF-8";

.logic {
  .kv {
    background: url(../img/logic/kv.jpg) no-repeat center;
    background-size: cover;
    &__ttl {
      line-height: 1.35;
    }
  }
  .sec {
    max-width: 800px;
    margin: 0 auto;
    &.pb60 {
      padding: 0 0 60px;
    }
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    .upperLetter {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 0 100px;
      @include sp-screen {
        display: block;
        margin: 0 0 50px;
      }
      &__left {
        margin: 0 4% 0 0;
        @include sp-screen {
          margin: 0 0 20px;
        }
      }
      ul {
        width: 48%;
        @include sp-screen {
          width: 100%;
        }
        li {
          display: flex;
          align-items: center;
          margin: 0 0 40px;
          @include sp-screen {
            margin: 0 0 20px;
          }
          &:last-child {
            margin: 0;
          }
          .num {
            color: #52BC56;
            display: inline-block;
            font-family: 'Norwester Regular';
            font-size: 50px;
            padding: 5px 30px 5px 0;
            margin: 0 10px 0 0;
            border-right: 1px solid #707070;
            line-height: .8;
            width: 30px;
            @include sp-screen {
              font-size: 40px;
              padding: 5px 20px 5px 0;
            }
          }
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.5;
            width: calc(100% - 71px);
            @include sp-screen {
              font-size: 15px;
            }
          }
        }
      }
    }
    .lowerLetter {
      margin: 0 0 50px;
      @include sp-screen {
        margin: 0 0 30px;
      }
      li {
        display: flex;
        align-items: center;
        margin: 0 0 40px;
        @include sp-screen {
          margin: 0 0 20px;
        }
        &:last-child {
          margin: 0;
        }
        .num {
          color: #52BC56;
          display: inline-block;
          font-family: 'Norwester Regular';
          font-size: 40px;
          padding: 5px 20px 5px 0;
          margin: 0 10px 0 0;
          border-right: 1px solid #707070;
          line-height: .8;
          width: 40px;
          text-align: center;
          @include sp-screen {
            font-size: 30px;
            padding: 5px 20px 5px 0;
            width: 30px;
          }
        }
        h4 {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          width: calc(100% - 71px);
          @include sp-screen {
            font-size: 12px;
          }
        }
      }
    }
    .logic02--01 {
      max-width: 556px;
      margin: 0 auto;
    }
    .logic02--02 {
      max-width: 476px;
      margin: 0 auto;
    }
    .logic02--03 {
      margin: 30px 0 0;
    }
    .rightBox {
      &--logic01 {
        .txtBox {
          width: calc(100% - 416px);
          @include sp-screen {
            width: 100%;
          }
        }
        .imgBox {
          width: 316px;
          @include sp-screen {
            width: 100%;
          }
        }
      }
    }
  }
}
