@charset "UTF-8";

.inner {
  width: calc(100% - 10vw);
  max-width: 1600px;
  margin: 0 auto;
}

.ttl {
  &--en {
    @include norwester;
  }
  &--sub {
    color: $d-gray;
  }
}

.lead {
  width: 800px;
  margin: 0 auto 80px;
  @include sp-screen {
    width: calc(100% - 9.37vw);
  }
  p {
    margin-bottom: 1em;
  }
}

.sec {
  padding-bottom: 128px;
  @include sp-screen {
    padding-bottom: 80px;
  }
  .contentTtl {
    font-size: 32px;
    line-height: 1.6875;
    margin: 0 0 20px;
    @include sp-screen {
      font-size: 20px;
    }
  }
  .contentSubTtl {
    font-size: 24px;
    line-height: 1.7;
    margin: 0 0 20px;
    padding: 0 0 0 20px;
    position: relative;
    @include sp-screen {
      padding: 0 0 0 14px;
      font-size: 16px;
    }
    &:before {
      content: "";
      background: #52BC56;
      display: block;
      width: 4px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 8px;
      @include sp-screen {
        width: 3px;
        height: 16px;
        top: 4px;
      }
    }
  }
  &__ttl {
    margin-bottom: 48px;
    @include sp-screen {
      margin-bottom: 32px;
    }
    .ttl {
      display: block;
      &--en {
        margin-bottom: 16px;
        font-size: 48px;
        line-height: 1.2;
        @include sp-screen {
          font-size: 32px;
        }
      }
      &--sub {
        font-size: 16px;
        font-weight: bold;
        @include sp-screen {
          font-size: 12px;
        }
      }
    }
  }
  &--textBox {
    p {
      text-align: justify;
      line-height: 1.86;
      margin: 0 0 30px;
      &:last-child {
        margin: 0;
      }
      strong {
        font-weight: bold;
      }
    }
    .tips {
      border: 1px solid #CCC;
      padding: 50px;
      margin: 0 0 40px;
      &--last {
        margin: 0;
      }
      @include sp-screen {
        padding: 20px;
      }
      ul {
        li {
          margin: 0 0 20px;
          @include sp-screen {
            margin: 0 0 10px;
          }
          &:last-child {
            margin: 0;
          }
          p {
            color: #52BC56;
            font-size: 21px;
            font-weight: 600;
            line-height: 1.42;
            position: relative;
            padding: 0 0 0 36px;
            @include sp-screen {
              font-size: 16px;
            }
            span {
              background: linear-gradient(transparent 40%, #FCFC0A 40%);
            }
            &:before {
              content: "";
              display: block;
              border-left: 4px solid #FF5259;
              border-bottom: 4px solid #FF5259;
              width:20px;
              height:10px;
              -webkit-transform:rotate(-45deg);
              transform:rotate(-45deg);
              left:0;
              top:50%;
              margin: -12px 0 0;
              position: absolute;
            }
          }
        }
      }
    }
    .icoBox {
      text-align: center;
      margin: 0 0 30px;
      padding: 20px 0;
    }
    .rightBox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &--center {
        align-items: center;
      }
      @include sp-screen {
        display: block;
      }
      .txtBox {
        width: calc(100% - 330px);
        @include sp-screen {
          width: 100%;
          margin: 0 0 20px;
        }
      }
      .imgBox {
        width: 300px;
        @include sp-screen {
          width: 100%;
        }
        img {
          display: block;
          width: 100%;
        }
        .caution {
          display: block;
          font-size: 11px;
          margin: 10px 0 0;
          line-height: 1.72;
        }
      }
    }
    .leftBox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction : row-reverse;
      &--center {
        align-items: center;
      }
      @include sp-screen {
        display: block;
      }
      .txtBox {
        width: calc(100% - 330px);
        @include sp-screen {
          width: 100%;
          margin: 0 0 20px;
        }
      }
      .imgBox {
        width: 300px;
        @include sp-screen {
          width: 100%;
        }
        img {
          display: block;
          width: 100%;
        }
        .caution {
          display: block;
          font-size: 11px;
          margin: 10px 0 0;
          line-height: 1.72;
        }
      }
    }
    .centerBox {
      .imgBox {
        img {
          display: block;
          width: 100%;
        }
        .caution {
          display: block;
          font-size: 11px;
          margin: 10px 0 0;
          line-height: 1.72;
        }
      }
    }
    .imglistBox {
      ul {
        display: flex;
        justify-content: space-between;
        li {
          width: 33%;
          margin: 0 .5% 0 0;
          &:last-child {
            margin: 0;
          }
          img {
            display: block;
            width: 100%;
          }
          .caution {
            display: block;
            font-size: 11px;
            margin: 10px 0 0;
            line-height: 1.72;
          }
        }
      }
      &--two {
        ul {
          li {
            width: 49%;
            margin: 0 2% 0 0;
          }
        }
      }
      &--other {
        ul {
          justify-content: flex-start;
          li {
            width: 300px;
            margin: 0 24px 0 0;
          }
        }
      }
      &--wrap {
        ul {
          flex-wrap: wrap;
          li {
            width: 49%;
            margin: 0 2% 24px 0;
            &:nth-child(2n) {
              margin: 0 0 24px;
            }
          }
        }
      }
    }
  }
  &--afterWord {
    background: #F2F2F2;
    padding: 70px 40px 30px;
    box-sizing: border-box;
    margin: 0 auto 128px!important;
    @include sp-screen {
      padding: 30px 16px 20px;
      margin: 0 auto 60px!important;
    }
    > h3 {
      font-size: 32px;
      line-height: 1.34;
      margin: 0 0 30px;
      @include sp-screen {
        font-size: 20px;
        margin: 0 0 20px;
      }
    }
    p {
      margin: 0 0 30px;
      font-weight: 600;
      @include sp-screen {
        margin: 0 0 20px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

.categoryLink {
  width: 800px;
  padding: 0 0 150px;
  margin: 0 auto;
  @include sp-screen {
    width: calc(100% - 9.37vw);
  }
  ul {
    overflow: hidden;
    li {
      width: 385px;
      height: 94px;
      @include sp-screen {
        width: 48%;
      }
      &.right {
        float: right;
        .linkBnr {
          padding: 0 50px 0 20px;
          @include sp-screen {
            padding: 0 30px 0 10px;
          }
          &:before {
            right: 15px;
            @include sp-screen {
              right: 5px;
            }
          }
          &:after {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            right: 21px;
            margin: -3px 0 0;
            @include sp-screen {
              right: 11px;
            }
          }
        }
      }
      &.left {
        float: left;
        .linkBnr {
          padding: 0 20px 0 50px;
          @include sp-screen {
            padding: 0 10px 0 30px;
          }
          &:before {
            left: 15px;
            @include sp-screen {
              left: 5px;
            }
          }
          &:after {
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
            left: 22px;
            margin: -3px 0 0;
            @include sp-screen {
              left: 11px;
            }
          }
        }
      }
      .linkBnr {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 315px;
        height: 94px;
        position: relative;
        @include sp-screen {
          width: 100%;
          box-sizing: border-box;
        }
        &:before {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          background: #52BC56;
          position: absolute;
          top: 50%;
          margin: -9px 0 0;
        }
        &:after {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          position: absolute;
          top: 50%;
        }
        .linkTxt {
          color: #FFF;
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          @include sp-screen {
            font-size: 10px;
          }
          &__ttl {
            font-family: 'Norwester Regular';
            font-size: 20px;
            font-weight: normal;
            display: block;
            @include sp-screen {
              font-size: 16px;
            }
          }
        }
        &--logic01 {
          background: url(../img/logic/bg_bnr_logic_01.jpg) no-repeat center;
          background-size: cover;
        }
        &--logic02 {
          background: url(../img/logic/bg_bnr_logic_02.jpg) no-repeat center;
          background-size: cover;
        }
        &--logic03 {
          background: url(../img/logic/bg_bnr_logic_03.jpg) no-repeat center;
          background-size: cover;
        }
        &--putting01 {
          background: url(../img/putting/bg_bnr_putting_01.jpg) no-repeat center;
          background-size: cover;
        }
      }
    }
  }
}
