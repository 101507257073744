@charset "UTF-8";

.index {
  .kv {
    width: 100%;
    color: $white;
    text-align: center;
    position: relative;
    z-index: 0;
    @include pc-screen {
      min-height: 768px;
      overflow: hidden;
    }
    &__img {
      position: relative;
      background-size: cover;
      @include pc-screen {
        min-height: 768px;
      }
      @include sp-screen {
        height: 400px;
      }
    }
    .inner {
      overflow: hidden;
      max-width: 740px;
      position: absolute;
      top: 50%;
      left: 35%;
      transform: translate(-50%, -50%);
      @include sp-screen {
        left: 50%;
      }
    }
    &__catch {
      padding: 48px 44px;
      background: rgba($black, 0.1);
      @include pc-screen {
        // float: right;
      }
      @include sp-screen {
        margin: 80px 0 40px;
        padding: 24px 16px;
      }
    }
    h1 {
      margin-bottom: 32px;
      font-size: 72px;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba($black,0.16);
      @include sp-screen {
        margin-bottom: 16px;
        font-size: 26px;
      }
      span {
        line-height: 1;
        font-size: 48px;
        @include sp-screen {
          font-size: 22px;
        }
      }
      i {
        margin: 0 24px;
        @include sp-screen {
          margin: 0 8px;
        }
      }
      .restraint {
        display: block;
        font-size: 32px;
        line-height: 1.6;
        @include sp-screen {
          font-size: 18px;
        }
      }
      .emphasis {
        position: relative;
        color: $secondary;
        font-size: 160px;
        @include norwester;
        @include sp-screen {
          font-size: 60px;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: rotate(-10deg) translate(-50%, -50%);
          transform-origin: 0 0;
          text-shadow: 2px 2px 0 #e4d55e;
          font-size: 30px;
          color: $accent;
          @include sp-screen {
            font-size: 14px;
          }
        }
      }
    }
    p {
      font-size: 16px;
      @include sp-screen {
        font-size: 14px;
        text-align-last: center;
      }
    }
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      @include norwester;
      font-size: 40px;
      @include sp-screen {
        width: 258px;
        margin: 20px auto 0;
        font-size: 14px;
      }
      a, .movie {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 170px;
        margin: 0 8px;
        border: solid 2px $white;
        box-sizing: border-box;
        color: $white;
        overflow: hidden;
        @include sp-screen {
          height: 70px;
          margin: 0 4px;
        }
        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: rgba($black, 0.5);
          transition: all .4s;
        }
        &:hover {
          opacity: 1;
          &::before {
            transform: scale(1.2);
          }
          &::after {
            opacity: 0;
          }
        }
        span {
          position: relative;
          z-index: 10;
        }
      }
      .instructor {
        &::before {
          background: url(../img/index/kv_instructor.jpg) no-repeat center;
          background-size: cover;
        }
      }
      .movie {
        &::before {
          background: url(//img.youtube.com/vi/SrKpAfFbJIA/mqdefault.jpg) no-repeat center;
          background-size: cover;
        }
        i {
          font-size: 64px;
          @include sp-screen {
            font-size: 24px;
          }
        }
      }
    }
  }

  .news {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 12px 0;
    font-size: 12px;
    background: rgba($black, 0.8);
    @include sp-screen {
      position: static;
    }
    &__wrap {
      @include pc-screen {
        width: 40%;
        display: flex;
        flex-flow: wrap;
        margin: 0 16px;
      }
    }
    .ttl {
      font-size: 32px;
      margin: 0 16px;
      @include sp-screen {
        width: 100%;
        margin: 12px 0 24px;
      }
    }
    a {
      color: $white;
    }
    .item {
      display: flex;
      width: 50%;
      margin: 12px 0;
      @include sp-screen {
        margin: 0 0 12px;
      }
    }
    .date {
      @include norwester;
      @include pc-screen {
        margin-right: 16px;
      }
      @include sp-screen {
        margin-right: 8px;
      }
    }
    .text {
      a {
        display: block;
        width: 20em;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .more {
      margin: 0 0 12px 12px;
      align-self: flex-end;
      @include sp-screen {
        margin: 12px 0;
        width: 100%;
      }
    }
  }

  .plan {
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
    @include sp-screen {
      flex-wrap: wrap;
      margin-top: 40px;
      padding: 0 6%;
    }
    .card {
      margin-left: 80px;
      @include sp-screen {
        margin: 0 0 64px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__text {
        height: auto;
        &:before {
          content: none;
        }
      }
    }
  }

  .sec--staff {
    .block__img {
      width: 42%;
      margin-right: 18%;
      @include sp-screen {
        width: 50%;
        margin-right: 0;
      }
    }
    .profile {
      font-size: 15px;
      line-height: 1.6;
      @include sp-screen {
        font-size: 14px;
      }
      dd {
        margin-bottom: calc(1em * 1.6);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .sec--pickup {
    .block__img {
      .scale__img {
        max-width: 760px;
        margin-left: auto;
      }
    }
  }
  & div:first-child {
    background-position: right top !important;
    @include sp-screen {
      background-position: right -160px top !important;
    }
  }
}

@include sp-screen {
  #colorbox {
    position: fixed !important;
    top: 50%;
    width: 100% !important;
    height: auto !important;
  }
  #cboxWrapper {
    width: 100% !important;
    height: auto !important;
  }
  #cboxContent {
    width: 100% !important;
    background: none !important;
    margin-top: 20vw;
  }
  #cboxLoadedContent {
    width: 100% !important;
    height: auto !important;
    background: none;
    position:relative;
    width:100%;
    height:0!important;
    padding-top:56%;
    #modalCm,iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}
