@charset "UTF-8";

.kv {
  margin-bottom: 80px;
  @include sp-screen {
    margin-bottom: 40px;
  }
  &--lower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52vh;
    color: $white;
    text-align: center;
    position: relative;
  }
  &__ttl {
    font-size: 20px;
    @include sp-screen {
      font-size: 16px;
    }
    .ttl--en {
      display: block;
      margin-bottom: 24px;
      font-size: 64px;
      @include sp-screen {
        font-size: 40px;
      }
    }
  }
}