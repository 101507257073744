@charset "UTF-8";

body {
  background: $primary;
}
main {
  background: $white;
}

.pc-on {
  display: block;
}

.sp-on {
  display: none;
}

.pc-i-on {
  display: inline-block;
}

.sp-i-on {
  display: none;
}

body {
  @include fontSansSerif;
  font-size: 16px;
  @include pc-screen {
    min-width: 1080px;
  }
}

a {
  text-decoration: none;
  transition: all 0.5s;
  color: $black;
  img {
    transition: opacity 0.5s;
    backface-visibility: hidden;
  }
  &:hover {
    opacity: 0.7;
    text-decoration: none;
    img {
      opacity: 0.7;
    }
  }
}

p {
  font-size: 15px;
  line-height: 1.8;
  @include sp-screen {
    font-size: 14px;
  }
}

input[type="text"],
input[type="button"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
textarea {
  box-sizing: border-box;
  appearance: none;
  max-width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: $white;
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  font-size: 16px;
  @include sp-screen {
    padding: 16px;
  }
  &::placeholder {
    color: #aaa;
  }
}

.calendar {
  @include pc-screen {
    position: relative;
    background: $white;
    input {
      background: transparent;
      position: relative;
      z-index: 1;
    }
    i {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      color: #aaa;
    }
  }
  @include sp-screen {
    i {
      display: none;
    }
  }
}

.radio,
.checkbox {
  display: none;
  & + label{
    position:relative;
    display: inline-block;
    margin-right: 32px;
    padding: 8px 8px 8px 48px;
  }
  & + label::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $white;
  }
  &:checked + label{
    &::before {
      background: $black;
      box-sizing: border-box;
    }
    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 16px;
      width: 16px;
      height: 16px;
      background: $white;
      border-radius: 50%;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include sp-screen {
    justify-content: flex-start;
  }
  &::after {
    content: "";
    padding: 4px;
    font-size: 11px;
    color: $white;
    margin-left: 8px;
  }
  &--required {
    &::after {
      content: "必須";
      background: $accent;
    }
  }
}

@include sp-screen {

  .pc-on {
    display: none;
  }

  .sp-on {
    display: block;
  }

  .pc-i-on {
    display: none;
  }

  .sp-i-on {
    display: inline-block;
  }

  input[type="text"],
  input[type="button"],
  input[type="email"],
  input[type="submit"],
  input[type="password"],
  textarea {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-appearance: none;
      max-width: 100%;
      border-radius: 0;
  }

}

a[href^="tel:"] {
  color: #000;
  pointer-events: none;
  @include sp-screen {
    color: $black;
    pointer-events: auto;
  }
}
