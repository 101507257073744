@charset "UTF-8";

.simulation {
  .kv {
    background: url(../img/simulation/kv.jpg) no-repeat center;
    background-size: cover;
    &__ttl {
      color: $black;
    }
    .ttl--bg {
      width: 1000px;
      text-align: center;
      line-height: 1.2;
      color: $white;
      background-color: rgba($color: #0D0303, $alpha: .4);
      @include sp-screen {
        width: calc(100% - 9.37vw);
        margin: 0 auto 24px;
        padding: 10px 20px;
        box-sizing: border-box;
      }
    }
  }
  .lead {
    &__ttl {
      margin-bottom: 40px;
      font-size: 32px;
      line-height: 1.6;
      @include sp-screen {
        margin-bottom: 32px;
        font-size: 24px;
      }
    }
    &__movie {
      position: relative;
      margin-top: 25px;
      padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
      height: 0;
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .sec {
    width: 800px;
    margin: 0 auto;
    padding-bottom: 80px;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    &:last-child {
      padding-bottom: 128px;
    }
    &__ttl {
      position: relative;
      margin-bottom: 32px;
      padding-left: 16px;
      font-size: 24px;
      line-height: 1.4;
      border-left: 4px solid $primary;
      @include sp-screen {
        margin-bottom: 24px;
        font-size: 16px;
        padding-left: 8px;
      }
    }
    .note {
      font-size: 11px;
    }
    .btn {
      max-width: 415px;
      margin: 40px auto 0;
      @include sp-screen {
        margin: 24px auto 0;
      }
    }
    .flow {
      li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 88px;
        @include sp-screen {
          display: block;
        }
        &:last-child {
          margin-bottom: 32px;
          &::after {
            display: none;
          }
        }
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            .flow__bg {
              background: url(../img/simulation/img0#{$i}.jpg) no-repeat center / cover;
            }
          }
        }
        &::after {
          position: absolute;
          left: 50%;
          bottom: -56px;
          transform: translate(-50%);
          content: "";
          display: block;
          width: 16px;
          height: 24px;
          background: url(../img/simulation/arrow.svg) no-repeat center / contain;
        }
      }
      &__bg {
        width: 260px;
        height: 150px;
        flex: 0 0 260px;
        margin-right: 20px;
        @include sp-screen {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .note {
        margin-top: 10px;
        padding: 10px 15px;
        background-color: $gray;
        font-size: 14px;
      }
    }
    .pro-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include sp-screen {
        display: block;
      }
      &::after {
        content: "";
        display: block;
        width: 30%;
        @include sp-screen {
          display: none;
        }
      }
    }
    .pro-box {
      width: 30%;
      @include sp-screen {
        width: 100%;
        margin-bottom: 60px;
      }
      &__img {
        width: 100%;
        height: 240px;
        margin-bottom: 24px;
        @include sp-screen {
          width: 240px;
          height: 240px;
          margin: 0 auto 24px;
        }
      }
      &__name {
        margin-bottom: 24px;
        font-size: 18px;
        @include sp-screen {
          font-size: 14px;
          margin-bottom: 16px;
        }
      }
      p {
        font-size: 11px;
      }
      @for $i from 1 through 2 {
        &:nth-child(#{$i}) {
          .pro-box__img {
            background: url(../img/simulation/pro0#{$i}.jpg) no-repeat center / cover;
          }
        }
      }
    }
  }
  .more__child {
    max-width: 415px;
  }
}
