@charset "UTF-8";

.header {
  position: fixed;
  top: 16px;
  left: 0;
  width: 100%;
  color: $white;
  font-size: 12px;
  z-index: 100;
  @include pc-screen {
    min-width: 1080px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include sp-screen {
      width: 100%;
      margin: 0;
    }
  }
  .menu-area {
    display: flex;
    align-items: center;
    flex: 0 1 360px;
    width: 360px;
    @include sp-screen {
      flex: 0 1 88px;
      width: 88px;
    }
  }
  .menu-btn {
    @include menu-trigger($bg:transparent,$span:$white,$round:0,$size:60px);
    @include pc-screen {
      transform: translateX(-16px);
    }
    @include sp-screen {
      @include menu-trigger($bg:transparent,$span:$white,$round:0,$size:44px);
      span {
        height: 2px;
      }
      &.active {
        span:nth-of-type(1) {
          transform: translateY(6px) rotate(-315deg);
        }
        span:nth-of-type(3) {
          transform: translateY(-5px) rotate(315deg);
        }
      }
    }
    @include pc-screen {
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  .logo {
    flex: 1 1 232px;
    text-align: center;
    @include sp-screen {
      flex: 1 1 10em;
    }
    .icon-logo {
      color: $white;
    }
  }
  .tel {
    display: flex;
    align-items: center;
    @include pc-screen {
      &.sp-on {
        display: none;
      }
    }
    @include sp-screen {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      font-size: 16px;
      color: $white;
      &.pc-on {
        display: none;
      }
    }
    dt {
      display: flex;
      align-items: center;
      @include norwester;
      font-size: 20px;
      margin-right: 1.6vw;
      &::before {
        font-family: "Font Awesome 5 Free";
        content: '\f095';
        display: inline-block;
        margin-right: 8px;
        transform: scaleX(-1);
        font-size: 16px;
        font-weight: 900;
      }
    }
  }
  .member {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 360px;
    @include sp-screen {
      flex: 0 1 88px;
    }
    li {
      margin-right: 48px;
      @include sp-screen {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    i {
      @include pc-screen {
        margin-right: 8px;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      @include sp-screen {
        width: 44px;
        height: 44px;
      }
    }
  }
  &.scroll {
    background: $white;
    color: $black;
    top: 0;
    .menu-btn {
      @include menu-trigger($bg:transparent,$span:$black,$round:0,$size:60px);
      @include sp-screen {
        @include menu-trigger($bg:transparent,$span:$black,$round:0,$size:44px);
        span {
          height: 2px;
        }
        &.active {
          span:nth-of-type(1) {
            transform: translateY(6px) rotate(-315deg);
          }
          span:nth-of-type(3) {
            transform: translateY(-5px) rotate(315deg);
          }
        }
      }
    }
    .logo {
      .icon-logo {
        color: $black;
      }
    }
    .tel {
      @include sp-screen {
        color: $black;
      }
    }
    .member {
      a {
        color: $black;
      }
    }
  }
}