@charset "UTF-8";

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: $black;
  z-index: 90;
  width: 100%;
  padding: 100px 0 40px;
  transition: all .4s;
  @include sp-screen {
    padding: 80px 0 40px;
  }
  &.active {
    display: block;
  }
  .navi {
    display: flex;
    font-size: 15px;
    flex-wrap: wrap;
    @include sp-screen {
      flex-direction: column;
    }
    li {
      position: relative;
      @include pc-screen {
        margin-right: 40px;
      }
      @include sp-screen {
        margin-bottom: 16px;
      }
      &:last-child {
        margin: 0;
      }
    }
    a,span {
      display: block;
      color: $white;
      line-height: 2;
    }
    .list {
      display: none;
      &.active {
        display: block;
        @include pc-screen {
          position: absolute;
          top: 30px;
          left: -20px;
          width: 10em;
        }
        @include sp-screen {
          padding: 0 0 0 1em;
        }
        li {
          margin: 0;
        }
        a {
          @include pc-screen {
            padding: 8px 20px;
            background: $black;
            &:hover {
              color: $black;
              background: $white;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@include sp-screen {}
