@charset "UTF-8";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  padding: 16px;
  box-sizing: border-box;
  border: solid 1px $black;
  background: $white;
  color: $black;
  font-size: 12px;
  &:hover {
    background: $black;
    color: $white;
  }
  &--black {
    background: $black;
    color: $white;
    &.more {
      color: $white;
    }
    &:hover {
      background: $white;
      color: $black;
    }
  }
}

.more {
  @include norwester;
  font-size: 12px;
  color: $black;
  &::before {
    font-family: "Font Awesome 5 Free";
    content: '\f105';
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 900;
    transform: rotate(90deg);
  }
  &.active {
    background: $white;
    color: $black;
    &:hover {
      background: $black;
      color: $white;
    }
    &::before {
      transform: rotate(-90deg);
    }
  }
  &__child {
    display: none;
    margin: auto;
    border: 1px solid $black;
    &.active {
      display: flex;
      flex-direction: column;
      transition: all .4s;
      li a {
        margin: 0;
        padding: 16px;
        display: flex;
        align-items: center;
        font-size: 16px;
        border-bottom: 1px solid $black;
        background: $white;
        &:hover {
          color: $white;
          background: $primary;
        }
        &.is-disabled {
          pointer-events: none;
          color: $accent;
          background: $secondary;
          border-bottom: none;
        }
      }
    }
  }
}
