@charset "UTF-8";

.contact {
  .kv {
    background: url(../img/contact/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .lead {
    a {
      text-decoration: underline;
    }
  }
  .form {
    background: $gray;
    overflow: hidden;
    form {
      max-width: 800px;
      margin: 0 auto;
      padding: 80px 0 0;
      @include sp-screen {
        padding: 40px 0;
      }
      dl {
        display: flex;
        margin-bottom: 24px;
        @include sp-screen {
          flex-wrap: wrap;
        }
        dt {
          width: 30%;
          padding: 16px 32px 0 0;
          box-sizing: border-box;
          @include sp-screen {
            width: 100%;
            margin-bottom: 16px;
            padding: 0;
          }
        }
        dd {
          width: 70%;
          @include sp-screen {
            width: 100%;
          }
        }
      }
      .btn {
        width: 320px;
        margin: 80px auto 0;
        @include sp-screen {
          width: 100%;
          margin: 40px auto 0;
        }
      }
    }
  }
  .php-errorText,
  .eBox-text #name-error,
  .eBox-text #mail1-error,
  .eBox-text #mail2-error,
  .eBox-text #tel-error,
  .eBox-text #postalCode-error,
  .eBox-text #textarea-error {
    color: #FF5259;
    margin: 0 0 24px 30%;
    @include sp-screen {
      margin: 0 0 24px 0;
    }
  }
}
.contact-thanks {
    .lead {
    min-height: 20vh;
    margin-bottom: 0;
    padding-bottom: 128px;
  }
}
