@charset "UTF-8";

.studio {
  .kv {
    background: url(../img/studio/kv.jpg) no-repeat center;
    background-size: cover;
  }
  .sec {
    padding-bottom: 0;
    &__ttl {
      font-size: 32px;
      line-height: 1.6;
      @include sp-screen {
        font-size: 24px;
      }
    }
    &__s-ttl {
      position: relative;
      margin-bottom: 32px;
      padding-left: 16px;
      font-size: 24px;
      line-height: 1.4;
      border-left: 4px solid $primary;
      @include sp-screen {
        margin-bottom: 24px;
        font-size: 16px;
        padding-left: 8px;
      }
    }
  }
  .lead {
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp-screen {
      display: block;
    }
    &__textArea {
      width: 56.25%;
      @include sp-screen {
        width: 100%;
      }
    }
    &__ttl {
      margin-bottom: 30px;
    }
    &__text {
      line-height: (28 / 15);
    }
    &__img {
      width: 38.75%;
      margin-left: 5%;
      @include sp-screen {
        width: 100%;
        margin: 0;
      }
      img {
        max-width: 100%;
      }
    }
  }
  .points {
    position: relative;
    padding-bottom: 120px;
    background-color: $gray;
    @include sp-screen {
      padding-bottom: 80px;
    }
    &::before {
      position: absolute;
      right: 0;
      top: 40px;
      display: block;
      width: 90%;
      height: calc(100% - 80px);
      content: "";
      background-color: $white;
      @include sp-screen {
        left: 50%;
        right: inherit;
        transform: translateX(-50%);
      }
    }
  }
  .point {
    position: relative;
    z-index: 1;
    width: 800px;
    margin: 0 auto;
    padding-top: 120px;
    @include sp-screen {
      width: calc(100% - 9.37vw);
      padding: 80px 24px 0;
      box-sizing: border-box;
    }
    &__list {
      counter-reset: num;
      font-weight: bold;
      font-size: 15px;
      line-height: 2;
      li {
        display: flex;
        align-items: center;
        margin-top: 30px;
        &::before {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 44px;
          margin-right: 32px;
          counter-increment: num;
          content: counter(num, decimal-leading-zero);
          font-family: 'Norwester Regular';
          font-size: 40px;
          line-height: 1;
          color: $primary;
          @include sp-screen {
            flex: 0 0 32px;
            margin-right: 24px;
            font-size: 32px;
          }
        }
        p {
          position: relative;
          padding-left: 24px;
          border-left: solid 2px $black;
          &::before,
          &::after {
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 16px 7px 0;
            border-color: transparent $black transparent transparent;
          }
          &::after {
            left: -11px;
            border-color: transparent $white transparent transparent;
          }
          span {
            background: linear-gradient(transparent 40%, #FCFC0A 40%);
          }
        }
      }
    }
  }
  .staff {
    width: 800px;
    margin: 80px auto 0;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
  }
  .staff-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 64px;
    @include sp-screen {
      flex-direction: column-reverse;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &:nth-child(odd) {
      @include pc-screen {
        flex-direction: row-reverse;
      }
    }
    &__img {
      width: 41.25%;
      @include sp-screen {
        width: 50%;
        max-width: 330px;
        margin: 0 auto 24px;
      }
      img {
        width: 100%;
      }
    }
    &__textArea {
      width: 51.75%;
      @include sp-screen {
        width: 100%;
      }
    }
    .profile {
      &__name {
        font-size: 18px;
        @include sp-screen {
          font-size: 14px;
        }
      }
      &__text {
        margin-top: 24px;
        font-size: 13px;
        line-height: (23 / 13);
        @include sp-screen {
          margin-top: 16px;
          font-size: 11px;
        }
      }
    }
    .comment {
      margin-top: 20px;
      padding: 24px 16px;
      background-color: #F2F2F2;
      font-size: 13px;
      line-height: (23 / 13);
      @include sp-screen {
        margin-top: 16px;
        padding: 16px;
        font-size: 11px;
      }
    }
  }
  .price {
    width: 800px;
    margin: 80px auto 0;
    padding-bottom: 160px;
    @include sp-screen {
      width: calc(100% - 9.37vw);
    }
    .btn {
      max-width: 415px;
      margin: 60px auto 0;
      @include sp-screen {
        margin-top: 40px;
      }
    }
    .more__child {
      max-width: 415px;
    }
  }
  .price-cont {
    &:not(:first-child) {
      margin-top: 40px;
    }
    .sec__s-ttl {
      margin-bottom: 15px;
    }
  }
  .price-blockArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .price-block {
    display: flex;
    flex-direction: column;
    width: 32.25%;
    margin: 5px 0;
    padding: 16px;
    font-size: 11px;
    box-sizing: border-box;
    background-color: #F2F2F2;
    border: solid 1px #E8EAE8;
    &:empty {
      margin: 0;
      padding: 0;
      background: transparent;
      border: none;
    }
    @include sp-screen {
      width: 100%;
      font-size: 12px;
    }
    &__ttl {
      font-weight: bold;
      line-height: (19 / 11);
    }
    &__text {
      font-size: 11px;
    }
    &__price {
      margin-top: auto;
      padding-top: 10px;
      text-align: right;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
