@charset "UTF-8";
@font-face {
  font-family: 'Norwester Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Norwester Regular"), url("../font/norwester.woff") format("woff"); }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden; }

body {
  background: #52BC56; }

main {
  background: #FFF; }

.pc-on {
  display: block; }

.sp-on {
  display: none; }

.pc-i-on {
  display: inline-block; }

.sp-i-on {
  display: none; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 16px; }
  @media screen and (min-width: 770px) {
    body {
      min-width: 1080px; } }

a {
  text-decoration: none;
  transition: all 0.5s;
  color: #333; }
  a img {
    transition: opacity 0.5s;
    backface-visibility: hidden; }
  a:hover {
    opacity: 0.7;
    text-decoration: none; }
    a:hover img {
      opacity: 0.7; }

p {
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 769px) {
    p {
      font-size: 14px; } }

input[type="text"],
input[type="button"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="date"],
textarea {
  box-sizing: border-box;
  appearance: none;
  max-width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: #FFF;
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  font-size: 16px; }
  @media screen and (max-width: 769px) {
    input[type="text"],
    input[type="button"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    textarea {
      padding: 16px; } }
  input[type="text"]::placeholder,
  input[type="button"]::placeholder,
  input[type="email"]::placeholder,
  input[type="password"]::placeholder,
  input[type="tel"]::placeholder,
  input[type="date"]::placeholder,
  textarea::placeholder {
    color: #aaa; }

@media screen and (min-width: 770px) {
  .calendar {
    position: relative;
    background: #FFF; }
    .calendar input {
      background: transparent;
      position: relative;
      z-index: 1; }
    .calendar i {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      color: #aaa; } }

@media screen and (max-width: 769px) {
  .calendar i {
    display: none; } }

.radio,
.checkbox {
  display: none; }
  .radio + label,
  .checkbox + label {
    position: relative;
    display: inline-block;
    margin-right: 32px;
    padding: 8px 8px 8px 48px; }
  .radio + label::before,
  .checkbox + label::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #FFF; }
  .radio:checked + label::before,
  .checkbox:checked + label::before {
    background: #333;
    box-sizing: border-box; }
  .radio:checked + label::after,
  .checkbox:checked + label::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 16px;
    width: 16px;
    height: 16px;
    background: #FFF;
    border-radius: 50%; }

.label {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .label {
      justify-content: flex-start; } }
  .label::after {
    content: "";
    padding: 4px;
    font-size: 11px;
    color: #FFF;
    margin-left: 8px; }
  .label--required::after {
    content: "必須";
    background: #FF5259; }

@media screen and (max-width: 769px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: block; }
  .pc-i-on {
    display: none; }
  .sp-i-on {
    display: inline-block; }
  input[type="text"],
  input[type="button"],
  input[type="email"],
  input[type="submit"],
  input[type="password"],
  textarea {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    border-radius: 0; } }

a[href^="tel:"] {
  color: #000;
  pointer-events: none; }
  @media screen and (max-width: 769px) {
    a[href^="tel:"] {
      color: #333;
      pointer-events: auto; } }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-logo {
  width: 19.3193359375em; }
  @media screen and (max-width: 769px) {
    .icon-logo {
      width: 10em; } }

.icon-arrow {
  width: 0.662109375em; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input, .numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 .5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/* component */
/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0); }

#cboxWrapper {
  max-width: none; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%; }

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left; }

#cboxContent {
  position: relative; }

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#cboxTitle {
  margin: 0; }

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer; }

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic; }

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0; }

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: url(../img/vendor/overlay.png) repeat 0 0;
  opacity: 0.9;
  filter: alpha(opacity=90); }

#colorbox {
  outline: 0; }

#cboxTopLeft {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -101px 0; }

#cboxTopRight {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -130px 0; }

#cboxBottomLeft {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -101px -29px; }

#cboxBottomRight {
  width: 0;
  height: 0;
  background: url(../img/vendor/controls.png) no-repeat -130px -29px; }

#cboxMiddleLeft {
  width: 0;
  background: url(../img/vendor/controls.png) left top repeat-y; }

#cboxMiddleRight {
  width: 0;
  background: url(../img/vendor/controls.png) right top repeat-y; }

#cboxTopCenter {
  height: 0;
  background: url(../img/vendor/border.png) 0 0 repeat-x; }

#cboxBottomCenter {
  height: 0;
  background: url(../img/vendor/border.png) 0 -29px repeat-x; }

#cboxContent {
  background: #fff;
  height: 472px !important; }

.cboxIframe {
  background: #fff; }

#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

#cboxLoadedContent {
  margin-bottom: 0; }

#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494; }

#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 58px;
  color: #949494; }

#cboxLoadingOverlay {
  background: url(../img/vendor/loading_background.png) no-repeat center center; }

#cboxLoadingGraphic {
  background: url(../img/vendor/loading.gif) no-repeat center center; }

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0; }

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef; }

#cboxPrevious {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../img/vendor/controls.png) no-repeat -75px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px; }

#cboxPrevious:hover {
  background-position: -75px -25px; }

#cboxNext {
  position: absolute;
  bottom: 0;
  left: 27px;
  background: url(../img/vendor/controls.png) no-repeat -50px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px; }

#cboxNext:hover {
  background-position: -50px -25px; }

#cboxClose {
  position: absolute;
  top: -40px;
  right: 0;
  background: url(../img/vendor/modal-close.png) no-repeat;
  width: 35px;
  height: 35px;
  text-indent: -9999px; }

#cboxClose:hover {
  opacity: 0.5; }

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF); }

.header {
  position: fixed;
  top: 16px;
  left: 0;
  width: 100%;
  color: #FFF;
  font-size: 12px;
  z-index: 100; }
  @media screen and (min-width: 770px) {
    .header {
      min-width: 1080px; } }
  .header .inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 769px) {
      .header .inner {
        width: 100%;
        margin: 0; } }
  .header .menu-area {
    display: flex;
    align-items: center;
    flex: 0 1 360px;
    width: 360px; }
    @media screen and (max-width: 769px) {
      .header .menu-area {
        flex: 0 1 88px;
        width: 88px; } }
  .header .menu-btn {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: relative;
    width: 60px;
    height: 60px;
    background-color: transparent;
    padding: 7.5px 0;
    z-index: 100; }
    .header .menu-btn span {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #FFF; }
      .header .menu-btn span:nth-of-type(1) {
        top: 16px;
        left: 0;
        right: 0;
        margin: auto;
        width: 46%; }
      .header .menu-btn span:nth-of-type(2) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 46%; }
      .header .menu-btn span:nth-of-type(3) {
        bottom: 16px;
        left: 0;
        right: 0;
        margin: auto;
        width: 46%; }
    .header .menu-btn.active span:nth-of-type(1) {
      -webkit-transform: translateY(10px) rotate(-315deg);
      transform: translateY(12px) rotate(-315deg); }
    .header .menu-btn.active span:nth-of-type(2) {
      opacity: 0; }
    .header .menu-btn.active span:nth-of-type(3) {
      -webkit-transform: translateY(-11px) rotate(315deg);
      transform: translateY(-11px) rotate(315deg); }
    @media screen and (min-width: 770px) {
      .header .menu-btn {
        transform: translateX(-16px); } }
    @media screen and (max-width: 769px) {
      .header .menu-btn {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: relative;
        width: 44px;
        height: 44px;
        background-color: transparent;
        padding: 7.5px 0;
        z-index: 100; }
        .header .menu-btn span {
          display: inline-block;
          transition: all .4s;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: #FFF; }
          .header .menu-btn span:nth-of-type(1) {
            top: 16px;
            left: 0;
            right: 0;
            margin: auto;
            width: 46%; }
          .header .menu-btn span:nth-of-type(2) {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 46%; }
          .header .menu-btn span:nth-of-type(3) {
            bottom: 16px;
            left: 0;
            right: 0;
            margin: auto;
            width: 46%; }
        .header .menu-btn.active span:nth-of-type(1) {
          -webkit-transform: translateY(10px) rotate(-315deg);
          transform: translateY(12px) rotate(-315deg); }
        .header .menu-btn.active span:nth-of-type(2) {
          opacity: 0; }
        .header .menu-btn.active span:nth-of-type(3) {
          -webkit-transform: translateY(-11px) rotate(315deg);
          transform: translateY(-11px) rotate(315deg); }
        .header .menu-btn span {
          height: 2px; }
        .header .menu-btn.active span:nth-of-type(1) {
          transform: translateY(6px) rotate(-315deg); }
        .header .menu-btn.active span:nth-of-type(3) {
          transform: translateY(-5px) rotate(315deg); } }
    @media screen and (min-width: 770px) {
      .header .menu-btn:hover {
        opacity: 0.7;
        cursor: pointer; } }
  .header .logo {
    flex: 1 1 232px;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .header .logo {
        flex: 1 1 10em; } }
    .header .logo .icon-logo {
      color: #FFF; }
  .header .tel {
    display: flex;
    align-items: center; }
    @media screen and (min-width: 770px) {
      .header .tel.sp-on {
        display: none; } }
    @media screen and (max-width: 769px) {
      .header .tel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        font-size: 16px;
        color: #FFF; }
        .header .tel.pc-on {
          display: none; } }
    .header .tel dt {
      display: flex;
      align-items: center;
      font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
      font-size: 20px;
      margin-right: 1.6vw; }
      .header .tel dt::before {
        font-family: "Font Awesome 5 Free";
        content: '\f095';
        display: inline-block;
        margin-right: 8px;
        transform: scaleX(-1);
        font-size: 16px;
        font-weight: 900; }
  .header .member {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 360px; }
    @media screen and (max-width: 769px) {
      .header .member {
        flex: 0 1 88px; } }
    .header .member li {
      margin-right: 48px; }
      @media screen and (max-width: 769px) {
        .header .member li {
          margin-right: 0; } }
      .header .member li:last-child {
        margin-right: 0; }
    @media screen and (min-width: 770px) {
      .header .member i {
        margin-right: 8px; } }
    .header .member a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF; }
      @media screen and (max-width: 769px) {
        .header .member a {
          width: 44px;
          height: 44px; } }
  .header.scroll {
    background: #FFF;
    color: #333;
    top: 0; }
    .header.scroll .menu-btn {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: relative;
      width: 60px;
      height: 60px;
      background-color: transparent;
      padding: 7.5px 0;
      z-index: 100; }
      .header.scroll .menu-btn span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #333; }
        .header.scroll .menu-btn span:nth-of-type(1) {
          top: 16px;
          left: 0;
          right: 0;
          margin: auto;
          width: 46%; }
        .header.scroll .menu-btn span:nth-of-type(2) {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 46%; }
        .header.scroll .menu-btn span:nth-of-type(3) {
          bottom: 16px;
          left: 0;
          right: 0;
          margin: auto;
          width: 46%; }
      .header.scroll .menu-btn.active span:nth-of-type(1) {
        -webkit-transform: translateY(10px) rotate(-315deg);
        transform: translateY(12px) rotate(-315deg); }
      .header.scroll .menu-btn.active span:nth-of-type(2) {
        opacity: 0; }
      .header.scroll .menu-btn.active span:nth-of-type(3) {
        -webkit-transform: translateY(-11px) rotate(315deg);
        transform: translateY(-11px) rotate(315deg); }
      @media screen and (max-width: 769px) {
        .header.scroll .menu-btn {
          display: inline-block;
          transition: all .4s;
          box-sizing: border-box;
          position: relative;
          width: 44px;
          height: 44px;
          background-color: transparent;
          padding: 7.5px 0;
          z-index: 100; }
          .header.scroll .menu-btn span {
            display: inline-block;
            transition: all .4s;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #333; }
            .header.scroll .menu-btn span:nth-of-type(1) {
              top: 16px;
              left: 0;
              right: 0;
              margin: auto;
              width: 46%; }
            .header.scroll .menu-btn span:nth-of-type(2) {
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 46%; }
            .header.scroll .menu-btn span:nth-of-type(3) {
              bottom: 16px;
              left: 0;
              right: 0;
              margin: auto;
              width: 46%; }
          .header.scroll .menu-btn.active span:nth-of-type(1) {
            -webkit-transform: translateY(10px) rotate(-315deg);
            transform: translateY(12px) rotate(-315deg); }
          .header.scroll .menu-btn.active span:nth-of-type(2) {
            opacity: 0; }
          .header.scroll .menu-btn.active span:nth-of-type(3) {
            -webkit-transform: translateY(-11px) rotate(315deg);
            transform: translateY(-11px) rotate(315deg); }
          .header.scroll .menu-btn span {
            height: 2px; }
          .header.scroll .menu-btn.active span:nth-of-type(1) {
            transform: translateY(6px) rotate(-315deg); }
          .header.scroll .menu-btn.active span:nth-of-type(3) {
            transform: translateY(-5px) rotate(315deg); } }
    .header.scroll .logo .icon-logo {
      color: #333; }
    @media screen and (max-width: 769px) {
      .header.scroll .tel {
        color: #333; } }
    .header.scroll .member a {
      color: #333; }

.fix-btn {
  position: relative;
  width: 240px;
  height: 230px;
  overflow: hidden;
  z-index: 80;
  position: fixed;
  top: inherit;
  bottom: 0;
  right: 0; }
  @media screen and (max-width: 769px) {
    .fix-btn {
      width: 210px;
      height: 200px; } }
  .fix-btn__inner {
    position: absolute;
    right: -20px;
    bottom: -20px;
    display: block;
    width: 140px;
    height: 140px;
    background: #52BC56;
    color: #FFF;
    font-size: 16px;
    text-align: center;
    line-height: 1.6;
    border-radius: 50%; }
    .fix-btn__inner:hover {
      cursor: pointer; }
    @media screen and (max-width: 769px) {
      .fix-btn__inner {
        width: 100px;
        height: 100px;
        font-size: 12px; } }
    .fix-btn__inner.active + .list {
      display: block; }
  .fix-btn .text {
    display: inline-block;
    transform: translate(-5px, 20px); }
    @media screen and (max-width: 769px) {
      .fix-btn .text {
        transform: translate(-7px, 12px); } }
  .fix-btn .emphasis {
    font-size: 24px; }
    @media screen and (max-width: 769px) {
      .fix-btn .emphasis {
        font-size: 16px; } }
  .fix-btn .list {
    display: none; }
    .fix-btn .list li a {
      width: 95px;
      height: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #FFF;
      background-color: #52BC56;
      border-radius: 15px; }
      .fix-btn .list li a.is-disabled {
        pointer-events: none;
        color: #FF5259;
        background-color: #FFEB52; }
    .fix-btn .list li:nth-child(1) {
      position: absolute;
      right: 50px;
      bottom: 160px;
      transform: rotate(60deg); }
      @media screen and (max-width: 769px) {
        .fix-btn .list li:nth-child(1) {
          right: 22px;
          bottom: 125px; } }
    .fix-btn .list li:nth-child(2) {
      position: absolute;
      right: 96px;
      bottom: 128px;
      transform: rotate(45deg); }
      @media screen and (max-width: 769px) {
        .fix-btn .list li:nth-child(2) {
          right: 61px;
          bottom: 97px;
          transform: rotate(40deg); } }
    .fix-btn .list li:nth-child(3) {
      position: absolute;
      right: 125px;
      bottom: 73px;
      transform: rotate(15deg); }
      @media screen and (max-width: 769px) {
        .fix-btn .list li:nth-child(3) {
          right: 87px;
          bottom: 53px; } }
    .fix-btn .list li:nth-child(4) {
      position: absolute;
      right: 127px;
      bottom: 20px;
      transform: rotate(0deg); }
      @media screen and (max-width: 769px) {
        .fix-btn .list li:nth-child(4) {
          right: 91px;
          bottom: 10px; } }

.footer {
  position: relative;
  width: 100%;
  padding: 68px 0;
  color: #FFF;
  font-size: 12px;
  background: #333; }
  .footer .inner a {
    color: #FFF; }
  .footer .logo-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px; }
    @media screen and (max-width: 769px) {
      .footer .logo-area {
        flex-wrap: wrap; } }
    .footer .logo-area .logo {
      display: flex; }
      @media screen and (max-width: 769px) {
        .footer .logo-area .logo {
          display: block;
          margin-bottom: 32px; } }
      .footer .logo-area .logo address {
        margin-left: 16px; }
        @media screen and (max-width: 769px) {
          .footer .logo-area .logo address {
            margin-top: 16px;
            margin-left: 0; } }
        .footer .logo-area .logo address span {
          margin-right: 1em; }
    @media screen and (max-width: 769px) {
      .footer .logo-area .icon-logo {
        width: 14em; } }
  .footer .member {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 360px; }
    @media screen and (max-width: 769px) {
      .footer .member {
        justify-content: flex-start; } }
    .footer .member li {
      margin-right: 48px; }
      .footer .member li:last-child {
        margin-right: 0; }
    .footer .member i {
      margin-right: 8px; }
    .footer .member a {
      display: block;
      color: #FFF; }
  .footer .navi,
  .footer .sub-navi {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 769px) {
      .footer .navi,
      .footer .sub-navi {
        flex-wrap: wrap; } }
    .footer .navi li,
    .footer .sub-navi li {
      margin: 0 24px 24px 0; }
      @media screen and (max-width: 769px) {
        .footer .navi li,
        .footer .sub-navi li {
          width: 100%;
          margin: 0 0 24px; } }
  @media screen and (max-width: 769px) {
    .footer .navi {
      margin-bottom: 16px; } }
  .footer .navi li {
    font-weight: bold; }
  .footer .copy {
    margin-top: 20px; }

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #333;
  z-index: 90;
  width: 100%;
  padding: 100px 0 40px;
  transition: all .4s; }
  @media screen and (max-width: 769px) {
    .menu {
      padding: 80px 0 40px; } }
  .menu.active {
    display: block; }
  .menu .navi {
    display: flex;
    font-size: 15px;
    flex-wrap: wrap; }
    @media screen and (max-width: 769px) {
      .menu .navi {
        flex-direction: column; } }
    .menu .navi li {
      position: relative; }
      @media screen and (min-width: 770px) {
        .menu .navi li {
          margin-right: 40px; } }
      @media screen and (max-width: 769px) {
        .menu .navi li {
          margin-bottom: 16px; } }
      .menu .navi li:last-child {
        margin: 0; }
    .menu .navi a, .menu .navi span {
      display: block;
      color: #FFF;
      line-height: 2; }
    .menu .navi .list {
      display: none; }
      .menu .navi .list.active {
        display: block; }
        @media screen and (min-width: 770px) {
          .menu .navi .list.active {
            position: absolute;
            top: 30px;
            left: -20px;
            width: 10em; } }
        @media screen and (max-width: 769px) {
          .menu .navi .list.active {
            padding: 0 0 0 1em; } }
        .menu .navi .list.active li {
          margin: 0; }
        @media screen and (min-width: 770px) {
          .menu .navi .list.active a {
            padding: 8px 20px;
            background: #333; }
            .menu .navi .list.active a:hover {
              color: #333;
              background: #FFF;
              opacity: 1; } }

.inner {
  width: calc(100% - 10vw);
  max-width: 1600px;
  margin: 0 auto; }

.ttl--en {
  font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }

.ttl--sub {
  color: #707070; }

.lead {
  width: 800px;
  margin: 0 auto 80px; }
  @media screen and (max-width: 769px) {
    .lead {
      width: calc(100% - 9.37vw); } }
  .lead p {
    margin-bottom: 1em; }

.sec {
  padding-bottom: 128px; }
  @media screen and (max-width: 769px) {
    .sec {
      padding-bottom: 80px; } }
  .sec .contentTtl {
    font-size: 32px;
    line-height: 1.6875;
    margin: 0 0 20px; }
    @media screen and (max-width: 769px) {
      .sec .contentTtl {
        font-size: 20px; } }
  .sec .contentSubTtl {
    font-size: 24px;
    line-height: 1.7;
    margin: 0 0 20px;
    padding: 0 0 0 20px;
    position: relative; }
    @media screen and (max-width: 769px) {
      .sec .contentSubTtl {
        padding: 0 0 0 14px;
        font-size: 16px; } }
    .sec .contentSubTtl:before {
      content: "";
      background: #52BC56;
      display: block;
      width: 4px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 8px; }
      @media screen and (max-width: 769px) {
        .sec .contentSubTtl:before {
          width: 3px;
          height: 16px;
          top: 4px; } }
  .sec__ttl {
    margin-bottom: 48px; }
    @media screen and (max-width: 769px) {
      .sec__ttl {
        margin-bottom: 32px; } }
    .sec__ttl .ttl {
      display: block; }
      .sec__ttl .ttl--en {
        margin-bottom: 16px;
        font-size: 48px;
        line-height: 1.2; }
        @media screen and (max-width: 769px) {
          .sec__ttl .ttl--en {
            font-size: 32px; } }
      .sec__ttl .ttl--sub {
        font-size: 16px;
        font-weight: bold; }
        @media screen and (max-width: 769px) {
          .sec__ttl .ttl--sub {
            font-size: 12px; } }
  .sec--textBox p {
    text-align: justify;
    line-height: 1.86;
    margin: 0 0 30px; }
    .sec--textBox p:last-child {
      margin: 0; }
    .sec--textBox p strong {
      font-weight: bold; }
  .sec--textBox .tips {
    border: 1px solid #CCC;
    padding: 50px;
    margin: 0 0 40px; }
    .sec--textBox .tips--last {
      margin: 0; }
    @media screen and (max-width: 769px) {
      .sec--textBox .tips {
        padding: 20px; } }
    .sec--textBox .tips ul li {
      margin: 0 0 20px; }
      @media screen and (max-width: 769px) {
        .sec--textBox .tips ul li {
          margin: 0 0 10px; } }
      .sec--textBox .tips ul li:last-child {
        margin: 0; }
      .sec--textBox .tips ul li p {
        color: #52BC56;
        font-size: 21px;
        font-weight: 600;
        line-height: 1.42;
        position: relative;
        padding: 0 0 0 36px; }
        @media screen and (max-width: 769px) {
          .sec--textBox .tips ul li p {
            font-size: 16px; } }
        .sec--textBox .tips ul li p span {
          background: linear-gradient(transparent 40%, #FCFC0A 40%); }
        .sec--textBox .tips ul li p:before {
          content: "";
          display: block;
          border-left: 4px solid #FF5259;
          border-bottom: 4px solid #FF5259;
          width: 20px;
          height: 10px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          left: 0;
          top: 50%;
          margin: -12px 0 0;
          position: absolute; }
  .sec--textBox .icoBox {
    text-align: center;
    margin: 0 0 30px;
    padding: 20px 0; }
  .sec--textBox .rightBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .sec--textBox .rightBox--center {
      align-items: center; }
    @media screen and (max-width: 769px) {
      .sec--textBox .rightBox {
        display: block; } }
    .sec--textBox .rightBox .txtBox {
      width: calc(100% - 330px); }
      @media screen and (max-width: 769px) {
        .sec--textBox .rightBox .txtBox {
          width: 100%;
          margin: 0 0 20px; } }
    .sec--textBox .rightBox .imgBox {
      width: 300px; }
      @media screen and (max-width: 769px) {
        .sec--textBox .rightBox .imgBox {
          width: 100%; } }
      .sec--textBox .rightBox .imgBox img {
        display: block;
        width: 100%; }
      .sec--textBox .rightBox .imgBox .caution {
        display: block;
        font-size: 11px;
        margin: 10px 0 0;
        line-height: 1.72; }
  .sec--textBox .leftBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse; }
    .sec--textBox .leftBox--center {
      align-items: center; }
    @media screen and (max-width: 769px) {
      .sec--textBox .leftBox {
        display: block; } }
    .sec--textBox .leftBox .txtBox {
      width: calc(100% - 330px); }
      @media screen and (max-width: 769px) {
        .sec--textBox .leftBox .txtBox {
          width: 100%;
          margin: 0 0 20px; } }
    .sec--textBox .leftBox .imgBox {
      width: 300px; }
      @media screen and (max-width: 769px) {
        .sec--textBox .leftBox .imgBox {
          width: 100%; } }
      .sec--textBox .leftBox .imgBox img {
        display: block;
        width: 100%; }
      .sec--textBox .leftBox .imgBox .caution {
        display: block;
        font-size: 11px;
        margin: 10px 0 0;
        line-height: 1.72; }
  .sec--textBox .centerBox .imgBox img {
    display: block;
    width: 100%; }
  .sec--textBox .centerBox .imgBox .caution {
    display: block;
    font-size: 11px;
    margin: 10px 0 0;
    line-height: 1.72; }
  .sec--textBox .imglistBox ul {
    display: flex;
    justify-content: space-between; }
    .sec--textBox .imglistBox ul li {
      width: 33%;
      margin: 0 .5% 0 0; }
      .sec--textBox .imglistBox ul li:last-child {
        margin: 0; }
      .sec--textBox .imglistBox ul li img {
        display: block;
        width: 100%; }
      .sec--textBox .imglistBox ul li .caution {
        display: block;
        font-size: 11px;
        margin: 10px 0 0;
        line-height: 1.72; }
  .sec--textBox .imglistBox--two ul li {
    width: 49%;
    margin: 0 2% 0 0; }
  .sec--textBox .imglistBox--other ul {
    justify-content: flex-start; }
    .sec--textBox .imglistBox--other ul li {
      width: 300px;
      margin: 0 24px 0 0; }
  .sec--textBox .imglistBox--wrap ul {
    flex-wrap: wrap; }
    .sec--textBox .imglistBox--wrap ul li {
      width: 49%;
      margin: 0 2% 24px 0; }
      .sec--textBox .imglistBox--wrap ul li:nth-child(2n) {
        margin: 0 0 24px; }
  .sec--afterWord {
    background: #F2F2F2;
    padding: 70px 40px 30px;
    box-sizing: border-box;
    margin: 0 auto 128px !important; }
    @media screen and (max-width: 769px) {
      .sec--afterWord {
        padding: 30px 16px 20px;
        margin: 0 auto 60px !important; } }
    .sec--afterWord > h3 {
      font-size: 32px;
      line-height: 1.34;
      margin: 0 0 30px; }
      @media screen and (max-width: 769px) {
        .sec--afterWord > h3 {
          font-size: 20px;
          margin: 0 0 20px; } }
    .sec--afterWord p {
      margin: 0 0 30px;
      font-weight: 600; }
      @media screen and (max-width: 769px) {
        .sec--afterWord p {
          margin: 0 0 20px; } }
      .sec--afterWord p:last-child {
        margin: 0; }

.categoryLink {
  width: 800px;
  padding: 0 0 150px;
  margin: 0 auto; }
  @media screen and (max-width: 769px) {
    .categoryLink {
      width: calc(100% - 9.37vw); } }
  .categoryLink ul {
    overflow: hidden; }
    .categoryLink ul li {
      width: 385px;
      height: 94px; }
      @media screen and (max-width: 769px) {
        .categoryLink ul li {
          width: 48%; } }
      .categoryLink ul li.right {
        float: right; }
        .categoryLink ul li.right .linkBnr {
          padding: 0 50px 0 20px; }
          @media screen and (max-width: 769px) {
            .categoryLink ul li.right .linkBnr {
              padding: 0 30px 0 10px; } }
          .categoryLink ul li.right .linkBnr:before {
            right: 15px; }
            @media screen and (max-width: 769px) {
              .categoryLink ul li.right .linkBnr:before {
                right: 5px; } }
          .categoryLink ul li.right .linkBnr:after {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            right: 21px;
            margin: -3px 0 0; }
            @media screen and (max-width: 769px) {
              .categoryLink ul li.right .linkBnr:after {
                right: 11px; } }
      .categoryLink ul li.left {
        float: left; }
        .categoryLink ul li.left .linkBnr {
          padding: 0 20px 0 50px; }
          @media screen and (max-width: 769px) {
            .categoryLink ul li.left .linkBnr {
              padding: 0 10px 0 30px; } }
          .categoryLink ul li.left .linkBnr:before {
            left: 15px; }
            @media screen and (max-width: 769px) {
              .categoryLink ul li.left .linkBnr:before {
                left: 5px; } }
          .categoryLink ul li.left .linkBnr:after {
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
            left: 22px;
            margin: -3px 0 0; }
            @media screen and (max-width: 769px) {
              .categoryLink ul li.left .linkBnr:after {
                left: 11px; } }
      .categoryLink ul li .linkBnr {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 315px;
        height: 94px;
        position: relative; }
        @media screen and (max-width: 769px) {
          .categoryLink ul li .linkBnr {
            width: 100%;
            box-sizing: border-box; } }
        .categoryLink ul li .linkBnr:before {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          background: #52BC56;
          position: absolute;
          top: 50%;
          margin: -9px 0 0; }
        .categoryLink ul li .linkBnr:after {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          position: absolute;
          top: 50%; }
        .categoryLink ul li .linkBnr .linkTxt {
          color: #FFF;
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5; }
          @media screen and (max-width: 769px) {
            .categoryLink ul li .linkBnr .linkTxt {
              font-size: 10px; } }
          .categoryLink ul li .linkBnr .linkTxt__ttl {
            font-family: 'Norwester Regular';
            font-size: 20px;
            font-weight: normal;
            display: block; }
            @media screen and (max-width: 769px) {
              .categoryLink ul li .linkBnr .linkTxt__ttl {
                font-size: 16px; } }
        .categoryLink ul li .linkBnr--logic01 {
          background: url(../img/logic/bg_bnr_logic_01.jpg) no-repeat center;
          background-size: cover; }
        .categoryLink ul li .linkBnr--logic02 {
          background: url(../img/logic/bg_bnr_logic_02.jpg) no-repeat center;
          background-size: cover; }
        .categoryLink ul li .linkBnr--logic03 {
          background: url(../img/logic/bg_bnr_logic_03.jpg) no-repeat center;
          background-size: cover; }
        .categoryLink ul li .linkBnr--putting01 {
          background: url(../img/putting/bg_bnr_putting_01.jpg) no-repeat center;
          background-size: cover; }

@media screen and (min-width: 770px) {
  .sec--access {
    width: calc(100% - (9.37vw / 2)); } }

@media screen and (max-width: 769px) {
  .sec--access iframe {
    width: 100%;
    height: 200px; } }

.sec--access .block {
  position: relative; }
  .sec--access .block::before {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    width: 23%;
    height: 350px;
    background: #EEEEEE;
    transform: translateY(-50%);
    z-index: 10; }
    @media screen and (max-width: 769px) {
      .sec--access .block::before {
        display: none; } }
  .sec--access .block__text {
    position: relative;
    z-index: 20; }

.block-wrap {
  padding: 64px 0;
  background: linear-gradient(to right, #FFF 0, #FFF 20%, #EEEEEE 20%, #EEEEEE 100%); }
  @media screen and (max-width: 769px) {
    .block-wrap {
      padding: 32px 0; } }

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF; }
  @media screen and (max-width: 769px) {
    .block {
      flex-direction: column; }
      .block--reverse {
        flex-direction: column-reverse; } }
  .block__text {
    width: 40%;
    padding: 0 6%;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1.6; }
    @media screen and (min-width: 770px) {
      .block__text {
        min-width: 460px; } }
    @media screen and (max-width: 769px) {
      .block__text {
        width: 100%;
        padding: 32px 6%; } }
    .block__text p span {
      font-weight: bold; }
    .block__text--bg {
      padding: 64px 6%; }
      @media screen and (max-width: 769px) {
        .block__text--bg {
          padding: 32px 6%; } }
    .block__text--wide {
      width: 46%;
      max-width: 460px;
      padding: 0 2%; }
      .block__text--wide p {
        min-height: 8.4rem; }
        @media screen and (max-width: 769px) {
          .block__text--wide p {
            min-height: auto; } }
    .block__text--widefull {
      width: 55%;
      max-width: 100%; }
    .block__text small {
      display: block;
      margin-top: 1em;
      color: #707070;
      font-size: 12px;
      line-height: 1.4; }
      .block__text small::before {
        content: "*"; }
  .block__img {
    width: 60%; }
    @media screen and (max-width: 769px) {
      .block__img {
        width: 100%; } }
    .block__img img {
      display: block;
      width: 100%; }
  .block--card {
    align-items: flex-start; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  padding: 16px;
  box-sizing: border-box;
  border: solid 1px #333;
  background: #FFF;
  color: #333;
  font-size: 12px; }
  .btn:hover {
    background: #333;
    color: #FFF; }
  .btn--black {
    background: #333;
    color: #FFF; }
    .btn--black.more {
      color: #FFF; }
    .btn--black:hover {
      background: #FFF;
      color: #333; }

.more {
  font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 12px;
  color: #333; }
  .more::before {
    font-family: "Font Awesome 5 Free";
    content: '\f105';
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 900;
    transform: rotate(90deg); }
  .more.active {
    background: #FFF;
    color: #333; }
    .more.active:hover {
      background: #333;
      color: #FFF; }
    .more.active::before {
      transform: rotate(-90deg); }
  .more__child {
    display: none;
    margin: auto;
    border: 1px solid #333; }
    .more__child.active {
      display: flex;
      flex-direction: column;
      transition: all .4s; }
      .more__child.active li a {
        margin: 0;
        padding: 16px;
        display: flex;
        align-items: center;
        font-size: 16px;
        border-bottom: 1px solid #333;
        background: #FFF; }
        .more__child.active li a:hover {
          color: #FFF;
          background: #52BC56; }
        .more__child.active li a.is-disabled {
          pointer-events: none;
          color: #FF5259;
          background: #FFEB52;
          border-bottom: none; }

.card {
  display: block;
  max-width: 37%;
  color: #333;
  overflow: hidden; }
  @media screen and (max-width: 769px) {
    .card {
      width: 100%;
      max-width: 100%; } }
  .card__img {
    margin-bottom: 24px; }
  .card__ttl {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold; }
    @media screen and (max-width: 769px) {
      .card__ttl {
        font-size: 16px; } }
  .card__price {
    margin-bottom: 24px;
    font-size: 12px; }
    .card__price span {
      font-size: 16px; }
    .card__price .name {
      margin-right: 1em; }
    .card__price i {
      margin-right: 8px; }
  .card__text {
    line-height: 1.6;
    height: 48px;
    overflow: hidden;
    position: relative;
    word-break: break-all;
    text-align: justify; }
    .card__text:before, .card__text:after {
      position: absolute; }
    .card__text:before {
      content: '...';
      background: #FFF;
      right: 0px;
      text-align: center;
      width: 1em !important;
      top: 24px; }
    .card__text:after {
      content: '';
      height: 100%;
      width: 100%;
      background: 0px;
      z-index: 2;
      background: #FFF; }
  .card .more {
    margin-top: 16px; }

.kv {
  margin-bottom: 80px; }
  @media screen and (max-width: 769px) {
    .kv {
      margin-bottom: 40px; } }
  .kv--lower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52vh;
    color: #FFF;
    text-align: center;
    position: relative; }
  .kv__ttl {
    font-size: 20px; }
    @media screen and (max-width: 769px) {
      .kv__ttl {
        font-size: 16px; } }
    .kv__ttl .ttl--en {
      display: block;
      margin-bottom: 24px;
      font-size: 64px; }
      @media screen and (max-width: 769px) {
        .kv__ttl .ttl--en {
          font-size: 40px; } }

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px; }
  .pagenation span, .pagenation a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin: 0 4px;
    border: solid 1px #333;
    box-sizing: border-box;
    font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }
    .pagenation span.current, .pagenation span:hover, .pagenation a.current, .pagenation a:hover {
      background: #333;
      color: #FFF; }
    .pagenation span.back, .pagenation a.back {
      width: 126px;
      padding: 0 40px; }
    .pagenation span:first-child, .pagenation span:last-child, .pagenation a:first-child, .pagenation a:last-child {
      margin: 0 20px; }

.scale__img {
  overflow: hidden; }

.scale img {
  transition: all .4s;
  width: 100%; }

.scale:hover img {
  transform: scale(1.2); }

.index .kv {
  width: 100%;
  color: #FFF;
  text-align: center;
  position: relative;
  z-index: 0; }
  @media screen and (min-width: 770px) {
    .index .kv {
      min-height: 768px;
      overflow: hidden; } }
  .index .kv__img {
    position: relative;
    background-size: cover; }
    @media screen and (min-width: 770px) {
      .index .kv__img {
        min-height: 768px; } }
    @media screen and (max-width: 769px) {
      .index .kv__img {
        height: 400px; } }
  .index .kv .inner {
    overflow: hidden;
    max-width: 740px;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 769px) {
      .index .kv .inner {
        left: 50%; } }
  .index .kv__catch {
    padding: 48px 44px;
    background: rgba(51, 51, 51, 0.1); }
    @media screen and (max-width: 769px) {
      .index .kv__catch {
        margin: 80px 0 40px;
        padding: 24px 16px; } }
  .index .kv h1 {
    margin-bottom: 32px;
    font-size: 72px;
    font-weight: bold;
    text-shadow: 0 3px 6px rgba(51, 51, 51, 0.16); }
    @media screen and (max-width: 769px) {
      .index .kv h1 {
        margin-bottom: 16px;
        font-size: 26px; } }
    .index .kv h1 span {
      line-height: 1;
      font-size: 48px; }
      @media screen and (max-width: 769px) {
        .index .kv h1 span {
          font-size: 22px; } }
    .index .kv h1 i {
      margin: 0 24px; }
      @media screen and (max-width: 769px) {
        .index .kv h1 i {
          margin: 0 8px; } }
    .index .kv h1 .restraint {
      display: block;
      font-size: 32px;
      line-height: 1.6; }
      @media screen and (max-width: 769px) {
        .index .kv h1 .restraint {
          font-size: 18px; } }
    .index .kv h1 .emphasis {
      position: relative;
      color: #FFEB52;
      font-size: 160px;
      font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }
      @media screen and (max-width: 769px) {
        .index .kv h1 .emphasis {
          font-size: 60px; } }
      .index .kv h1 .emphasis span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(-10deg) translate(-50%, -50%);
        transform-origin: 0 0;
        text-shadow: 2px 2px 0 #e4d55e;
        font-size: 30px;
        color: #FF5259; }
        @media screen and (max-width: 769px) {
          .index .kv h1 .emphasis span {
            font-size: 14px; } }
  .index .kv p {
    font-size: 16px; }
    @media screen and (max-width: 769px) {
      .index .kv p {
        font-size: 14px;
        text-align-last: center; } }
  .index .kv__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
    font-size: 40px; }
    @media screen and (max-width: 769px) {
      .index .kv__btn {
        width: 258px;
        margin: 20px auto 0;
        font-size: 14px; } }
    .index .kv__btn a, .index .kv__btn .movie {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 170px;
      margin: 0 8px;
      border: solid 2px #FFF;
      box-sizing: border-box;
      color: #FFF;
      overflow: hidden; }
      @media screen and (max-width: 769px) {
        .index .kv__btn a, .index .kv__btn .movie {
          height: 70px;
          margin: 0 4px; } }
      .index .kv__btn a::before, .index .kv__btn a::after, .index .kv__btn .movie::before, .index .kv__btn .movie::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.5);
        transition: all .4s; }
      .index .kv__btn a:hover, .index .kv__btn .movie:hover {
        opacity: 1; }
        .index .kv__btn a:hover::before, .index .kv__btn .movie:hover::before {
          transform: scale(1.2); }
        .index .kv__btn a:hover::after, .index .kv__btn .movie:hover::after {
          opacity: 0; }
      .index .kv__btn a span, .index .kv__btn .movie span {
        position: relative;
        z-index: 10; }
    .index .kv__btn .instructor::before {
      background: url(../img/index/kv_instructor.jpg) no-repeat center;
      background-size: cover; }
    .index .kv__btn .movie::before {
      background: url(//img.youtube.com/vi/SrKpAfFbJIA/mqdefault.jpg) no-repeat center;
      background-size: cover; }
    .index .kv__btn .movie i {
      font-size: 64px; }
      @media screen and (max-width: 769px) {
        .index .kv__btn .movie i {
          font-size: 24px; } }

.index .news {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 0;
  font-size: 12px;
  background: rgba(51, 51, 51, 0.8); }
  @media screen and (max-width: 769px) {
    .index .news {
      position: static; } }
  @media screen and (min-width: 770px) {
    .index .news__wrap {
      width: 40%;
      display: flex;
      flex-flow: wrap;
      margin: 0 16px; } }
  .index .news .ttl {
    font-size: 32px;
    margin: 0 16px; }
    @media screen and (max-width: 769px) {
      .index .news .ttl {
        width: 100%;
        margin: 12px 0 24px; } }
  .index .news a {
    color: #FFF; }
  .index .news .item {
    display: flex;
    width: 50%;
    margin: 12px 0; }
    @media screen and (max-width: 769px) {
      .index .news .item {
        margin: 0 0 12px; } }
  .index .news .date {
    font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }
    @media screen and (min-width: 770px) {
      .index .news .date {
        margin-right: 16px; } }
    @media screen and (max-width: 769px) {
      .index .news .date {
        margin-right: 8px; } }
  .index .news .text a {
    display: block;
    width: 20em;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .index .news .more {
    margin: 0 0 12px 12px;
    align-self: flex-end; }
    @media screen and (max-width: 769px) {
      .index .news .more {
        margin: 12px 0;
        width: 100%; } }

.index .plan {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px; }
  @media screen and (max-width: 769px) {
    .index .plan {
      flex-wrap: wrap;
      margin-top: 40px;
      padding: 0 6%; } }
  .index .plan .card {
    margin-left: 80px; }
    @media screen and (max-width: 769px) {
      .index .plan .card {
        margin: 0 0 64px; }
        .index .plan .card:last-child {
          margin-bottom: 0; } }
    .index .plan .card__text {
      height: auto; }
      .index .plan .card__text:before {
        content: none; }

.index .sec--staff .block__img {
  width: 42%;
  margin-right: 18%; }
  @media screen and (max-width: 769px) {
    .index .sec--staff .block__img {
      width: 50%;
      margin-right: 0; } }

.index .sec--staff .profile {
  font-size: 15px;
  line-height: 1.6; }
  @media screen and (max-width: 769px) {
    .index .sec--staff .profile {
      font-size: 14px; } }
  .index .sec--staff .profile dd {
    margin-bottom: calc(1em * 1.6); }
    .index .sec--staff .profile dd:last-child {
      margin-bottom: 0; }

.index .sec--pickup .block__img .scale__img {
  max-width: 760px;
  margin-left: auto; }

.index div:first-child {
  background-position: right top !important; }
  @media screen and (max-width: 769px) {
    .index div:first-child {
      background-position: right -160px top !important; } }

@media screen and (max-width: 769px) {
  #colorbox {
    position: fixed !important;
    top: 50%;
    width: 100% !important;
    height: auto !important; }
  #cboxWrapper {
    width: 100% !important;
    height: auto !important; }
  #cboxContent {
    width: 100% !important;
    background: none !important;
    margin-top: 20vw; }
  #cboxLoadedContent {
    width: 100% !important;
    height: auto !important;
    background: none;
    position: relative;
    width: 100%;
    height: 0 !important;
    padding-top: 56%; }
    #cboxLoadedContent #modalCm, #cboxLoadedContent iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

.lesson {
  padding-bottom: 128px; }
  .lesson .kv {
    background: url(../img/lesson/kv.jpg) no-repeat center;
    background-size: cover; }
  .lesson .ttl--target {
    display: flex;
    align-items: center; }
    .lesson .ttl--target .target {
      position: relative;
      height: 28px;
      margin-left: 24px;
      padding: 0 8px;
      font-size: 12px;
      line-height: 28px;
      background: #52BC56;
      color: #FFF; }
      .lesson .ttl--target .target::before {
        content: "";
        position: absolute;
        left: -28px;
        display: block;
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 14px solid transparent;
        border-right: 14px solid #52BC56; }
  .lesson .plan {
    margin-top: 32px; }
    .lesson .plan__block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 8px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.6; }
      .lesson .plan__block:last-child {
        margin-bottom: 0; }
      .lesson .plan__block--reccomend {
        background: #EEEEEE; }
    .lesson .plan__name {
      font-weight: bold; }
      .lesson .plan__name .tag {
        margin-left: 8px;
        padding: 4px;
        color: #000;
        font-size: 11px;
        font-weight: normal;
        background: #FFEB52; }
    .lesson .plan__price {
      text-align: right;
      font-size: 12px;
      font-weight: bold; }
      .lesson .plan__price span {
        font-size: 16px; }
      .lesson .plan__price i {
        margin: 0 8px; }
      .lesson .plan__price .note {
        font-size: 12px;
        font-weight: normal; }
  .lesson .block--card .plan {
    min-height: 150px; }
  .lesson .sec--ticket .block {
    position: relative;
    background: #707070;
    color: #FFF; }
    @media screen and (max-width: 769px) {
      .lesson .sec--ticket .block {
        padding-bottom: 11px; } }
    .lesson .sec--ticket .block::after {
      content: "";
      display: block;
      width: 22px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background: url(../img/lesson/bg_ticket.png) repeat-y; }
      @media screen and (max-width: 769px) {
        .lesson .sec--ticket .block::after {
          width: 100%;
          height: 11px;
          bottom: 0;
          top: initial;
          background: url(../img/lesson/bg_ticket_sp.png) repeat-x; } }
    .lesson .sec--ticket .block small {
      color: #FFF; }
  .lesson .sec--ticket .plan__block:nth-child(odd) {
    background: #888; }
  .lesson .sec--ticket .ttl--sub {
    color: #FFF; }
  .lesson .sec--simulation .block__img {
    width: 45%; }
    @media screen and (max-width: 769px) {
      .lesson .sec--simulation .block__img {
        width: 100%; } }

.facility .kv {
  background: url(../img/facility/kv.jpg) no-repeat center;
  background-size: cover; }

.facility .sec {
  position: relative;
  margin-bottom: 0; }
  .facility .sec.parallax-window {
    min-height: 464px;
    background: transparent; }
    @media screen and (max-width: 769px) {
      .facility .sec.parallax-window {
        position: sticky;
        top: 0; } }
  .facility .sec--01 .block {
    justify-content: flex-end; }
  .facility .sec--02 .block {
    justify-content: flex-start; }
  .facility .sec--03 .block {
    justify-content: flex-end; }
  .facility .sec--04 .block {
    justify-content: flex-start; }
  .facility .sec::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.5); }
  .facility .sec .block {
    background: none;
    color: #fff;
    z-index: 1;
    position: relative; }
  @media screen and (min-width: 770px) {
    .facility .sec .block__text {
      width: 52%; } }
  @media screen and (min-width: 770px) {
    .facility .sec .block__text--bg {
      padding: 128px 6%; } }
  @media screen and (max-width: 769px) {
    .facility .sec .block__text--bg {
      padding: 128px 0; } }
  .facility .sec__ttl {
    font-size: 32px;
    margin-bottom: 32px; }
    @media screen and (max-width: 769px) {
      .facility .sec__ttl {
        font-size: 24px; } }
    .facility .sec__ttl a {
      color: #FFF; }

.parallax-mirror {
  z-index: 0 !important; }

.about .kv {
  background: url(../img/about/kv.jpg) no-repeat center;
  background-size: cover; }

.about .block__text img {
  width: 100%; }

.about .block__text .info dl {
  display: flex; }
  .about .block__text .info dl dt {
    width: 9em;
    margin-right: 24px; }
    @media screen and (max-width: 769px) {
      .about .block__text .info dl dt {
        width: 7em;
        margin-right: 16px; } }
  .about .block__text .info dl dd {
    width: calc(100% - 9em - 24px); }
    @media screen and (max-width: 769px) {
      .about .block__text .info dl dd {
        width: calc(100% - 7em - 16px); } }

.about .block__text .use dt {
  font-size: 16px;
  font-weight: bold; }

.about .block__text .use a {
  text-decoration: underline; }

.about .block__text .use iframe {
  margin-top: 16px; }

.about .block__text .use p {
  margin-bottom: 24px; }

.about .block__text dl {
  margin-bottom: 24px;
  line-height: 1.6; }

.staff .kv {
  background: url(../img/staff/kv.jpg) no-repeat center;
  background-size: cover; }

.staff .sec--staff .sec__ttl {
  font-size: 32px;
  line-height: 1.4; }
  @media screen and (max-width: 769px) {
    .staff .sec--staff .sec__ttl {
      font-size: 24px; } }
  .staff .sec--staff .sec__ttl span {
    display: block;
    font-size: 18px; }
    @media screen and (max-width: 769px) {
      .staff .sec--staff .sec__ttl span {
        font-size: 14px; } }

.staff .sec--staff .sec__subttl {
  font-size: 24px; }
  @media screen and (max-width: 769px) {
    .staff .sec--staff .sec__subttl {
      font-size: 18px; } }

.staff .sec--staff .block__img {
  width: auto;
  max-width: 306px; }
  @media screen and (max-width: 769px) {
    .staff .sec--staff .block__img {
      width: auto;
      max-width: 124px; } }

.staff .sec--staff .profile {
  margin-bottom: 32px;
  font-size: 16px; }
  @media screen and (max-width: 769px) {
    .staff .sec--staff .profile {
      font-size: 16px; } }
  .staff .sec--staff .profile li {
    padding-left: 1em;
    text-indent: -1em; }
    .staff .sec--staff .profile li:before {
      content: "・";
      width: 1em;
      height: 1em; }

.staff .sec--staff .message {
  background: #EEEEEE;
  padding: 32px 24px; }

@media screen and (min-width: 770px) {
  .staff .sec--staff:nth-child(odd) .block__text {
    padding-right: 0; } }

@media screen and (min-width: 770px) {
  .staff .sec--staff:nth-child(even) .block__text {
    padding-left: 0; } }

.news .kv {
  background: url(../img/news/kv.jpg) no-repeat center;
  background-size: cover; }

.news .block {
  flex-wrap: wrap;
  justify-content: space-around; }

@media screen and (min-width: 770px) {
  .news .card {
    max-width: 30%;
    margin-bottom: 2%; } }

@media screen and (max-width: 769px) {
  .news .card {
    margin-bottom: 64px; } }

.news .card__img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  overflow: hidden; }

.news .card__date {
  margin-bottom: 16px;
  font-size: 12px;
  font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }

.news .detail {
  max-width: 800px;
  margin: 0 auto; }
  .news .detail__block {
    padding-bottom: 64px; }
  .news .detail__ttl {
    margin-bottom: 64px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.6; }
    @media screen and (max-width: 769px) {
      .news .detail__ttl {
        margin-bottom: 32px;
        font-size: 24px; } }
  .news .detail__date {
    margin-bottom: 24px;
    font-family: 'Norwester Regular', -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
    font-size: 12px; }
  .news .detail__text img {
    width: 100%;
    margin-bottom: 32px; }
  .news .detail .wp-block-image {
    margin: 1rem auto; }
  .news .detail a {
    color: #52BC56;
    border-bottom: 1px solid #52BC56; }

.contact .kv {
  background: url(../img/contact/kv.jpg) no-repeat center;
  background-size: cover; }

.contact .lead a {
  text-decoration: underline; }

.contact .form {
  background: #EEEEEE;
  overflow: hidden; }
  .contact .form form {
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 0 0; }
    @media screen and (max-width: 769px) {
      .contact .form form {
        padding: 40px 0; } }
    .contact .form form dl {
      display: flex;
      margin-bottom: 24px; }
      @media screen and (max-width: 769px) {
        .contact .form form dl {
          flex-wrap: wrap; } }
      .contact .form form dl dt {
        width: 30%;
        padding: 16px 32px 0 0;
        box-sizing: border-box; }
        @media screen and (max-width: 769px) {
          .contact .form form dl dt {
            width: 100%;
            margin-bottom: 16px;
            padding: 0; } }
      .contact .form form dl dd {
        width: 70%; }
        @media screen and (max-width: 769px) {
          .contact .form form dl dd {
            width: 100%; } }
    .contact .form form .btn {
      width: 320px;
      margin: 80px auto 0; }
      @media screen and (max-width: 769px) {
        .contact .form form .btn {
          width: 100%;
          margin: 40px auto 0; } }

.contact .php-errorText,
.contact .eBox-text #name-error,
.contact .eBox-text #mail1-error,
.contact .eBox-text #mail2-error,
.contact .eBox-text #tel-error,
.contact .eBox-text #postalCode-error,
.contact .eBox-text #textarea-error {
  color: #FF5259;
  margin: 0 0 24px 30%; }
  @media screen and (max-width: 769px) {
    .contact .php-errorText,
    .contact .eBox-text #name-error,
    .contact .eBox-text #mail1-error,
    .contact .eBox-text #mail2-error,
    .contact .eBox-text #tel-error,
    .contact .eBox-text #postalCode-error,
    .contact .eBox-text #textarea-error {
      margin: 0 0 24px 0; } }

.contact-thanks .lead {
  min-height: 20vh;
  margin-bottom: 0;
  padding-bottom: 128px; }

.policy .kv {
  background: url(../img/policy/kv.jpg) no-repeat center;
  background-size: cover; }

.policy .box {
  width: 800px;
  margin: 0 auto;
  margin-bottom: 64px; }
  @media screen and (max-width: 769px) {
    .policy .box {
      width: 100%; } }
  .policy .box__ttl {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.6; }
  .policy .box__text {
    margin-bottom: 24px; }
    .policy .box__text .logo {
      margin-bottom: 16px;
      font-size: 12px; }
      @media screen and (max-width: 769px) {
        .policy .box__text .logo {
          font-size: 16px; } }
    .policy .box__text dl {
      line-height: 1.8; }
  .policy .box__list {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.8;
    list-style: decimal;
    padding-left: 1em;
    font-family: "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif; }
    .policy .box__list li {
      margin-bottom: 8px; }
    .policy .box__list ul {
      margin-top: 8px; }
      .policy .box__list ul li::before {
        content: "●";
        width: 1em;
        display: inline-block;
        text-align: center; }

.simulation .kv {
  background: url(../img/simulation/kv.jpg) no-repeat center;
  background-size: cover; }
  .simulation .kv__ttl {
    color: #333; }
  .simulation .kv .ttl--bg {
    width: 1000px;
    text-align: center;
    line-height: 1.2;
    color: #FFF;
    background-color: rgba(13, 3, 3, 0.4); }
    @media screen and (max-width: 769px) {
      .simulation .kv .ttl--bg {
        width: calc(100% - 9.37vw);
        margin: 0 auto 24px;
        padding: 10px 20px;
        box-sizing: border-box; } }

.simulation .lead__ttl {
  margin-bottom: 40px;
  font-size: 32px;
  line-height: 1.6; }
  @media screen and (max-width: 769px) {
    .simulation .lead__ttl {
      margin-bottom: 32px;
      font-size: 24px; } }

.simulation .lead__movie {
  position: relative;
  margin-top: 25px;
  padding-bottom: 56.25%;
  /*アスペクト比 16:9の場合の縦幅*/
  height: 0;
  overflow: hidden; }
  .simulation .lead__movie iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.simulation .sec {
  width: 800px;
  margin: 0 auto;
  padding-bottom: 80px; }
  @media screen and (max-width: 769px) {
    .simulation .sec {
      width: calc(100% - 9.37vw); } }
  .simulation .sec:last-child {
    padding-bottom: 128px; }
  .simulation .sec__ttl {
    position: relative;
    margin-bottom: 32px;
    padding-left: 16px;
    font-size: 24px;
    line-height: 1.4;
    border-left: 4px solid #52BC56; }
    @media screen and (max-width: 769px) {
      .simulation .sec__ttl {
        margin-bottom: 24px;
        font-size: 16px;
        padding-left: 8px; } }
  .simulation .sec .note {
    font-size: 11px; }
  .simulation .sec .btn {
    max-width: 415px;
    margin: 40px auto 0; }
    @media screen and (max-width: 769px) {
      .simulation .sec .btn {
        margin: 24px auto 0; } }
  .simulation .sec .flow li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 88px; }
    @media screen and (max-width: 769px) {
      .simulation .sec .flow li {
        display: block; } }
    .simulation .sec .flow li:last-child {
      margin-bottom: 32px; }
      .simulation .sec .flow li:last-child::after {
        display: none; }
    .simulation .sec .flow li:nth-child(1) .flow__bg {
      background: url(../img/simulation/img01.jpg) no-repeat center/cover; }
    .simulation .sec .flow li:nth-child(2) .flow__bg {
      background: url(../img/simulation/img02.jpg) no-repeat center/cover; }
    .simulation .sec .flow li:nth-child(3) .flow__bg {
      background: url(../img/simulation/img03.jpg) no-repeat center/cover; }
    .simulation .sec .flow li:nth-child(4) .flow__bg {
      background: url(../img/simulation/img04.jpg) no-repeat center/cover; }
    .simulation .sec .flow li::after {
      position: absolute;
      left: 50%;
      bottom: -56px;
      transform: translate(-50%);
      content: "";
      display: block;
      width: 16px;
      height: 24px;
      background: url(../img/simulation/arrow.svg) no-repeat center/contain; }
  .simulation .sec .flow__bg {
    width: 260px;
    height: 150px;
    flex: 0 0 260px;
    margin-right: 20px; }
    @media screen and (max-width: 769px) {
      .simulation .sec .flow__bg {
        width: 100%;
        margin-bottom: 10px; } }
  .simulation .sec .flow .note {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #EEEEEE;
    font-size: 14px; }
  .simulation .sec .pro-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media screen and (max-width: 769px) {
      .simulation .sec .pro-area {
        display: block; } }
    .simulation .sec .pro-area::after {
      content: "";
      display: block;
      width: 30%; }
      @media screen and (max-width: 769px) {
        .simulation .sec .pro-area::after {
          display: none; } }
  .simulation .sec .pro-box {
    width: 30%; }
    @media screen and (max-width: 769px) {
      .simulation .sec .pro-box {
        width: 100%;
        margin-bottom: 60px; } }
    .simulation .sec .pro-box__img {
      width: 100%;
      height: 240px;
      margin-bottom: 24px; }
      @media screen and (max-width: 769px) {
        .simulation .sec .pro-box__img {
          width: 240px;
          height: 240px;
          margin: 0 auto 24px; } }
    .simulation .sec .pro-box__name {
      margin-bottom: 24px;
      font-size: 18px; }
      @media screen and (max-width: 769px) {
        .simulation .sec .pro-box__name {
          font-size: 14px;
          margin-bottom: 16px; } }
    .simulation .sec .pro-box p {
      font-size: 11px; }
    .simulation .sec .pro-box:nth-child(1) .pro-box__img {
      background: url(../img/simulation/pro01.jpg) no-repeat center/cover; }
    .simulation .sec .pro-box:nth-child(2) .pro-box__img {
      background: url(../img/simulation/pro02.jpg) no-repeat center/cover; }

.simulation .more__child {
  max-width: 415px; }

.theory .kv {
  background: url(../img/theory/kv.jpg) no-repeat center;
  background-size: cover; }
  .theory .kv__ttl {
    line-height: 1.35; }

.theory .sec {
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 769px) {
    .theory .sec {
      width: calc(100% - 9.37vw); } }
  .theory .sec--theoryList > .contentTtl {
    margin: 0 0 120px; }
    @media screen and (max-width: 769px) {
      .theory .sec--theoryList > .contentTtl {
        margin: 0 0 60px; } }
  .theory .sec--theoryList ul {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
    .theory .sec--theoryList ul li {
      width: 31%;
      margin: 0 3.5% 30px 0; }
      @media screen and (max-width: 769px) {
        .theory .sec--theoryList ul li {
          width: 48%;
          margin: 0 4% 15px 0; } }
      @media screen and (max-width: 769px) {
        .theory .sec--theoryList ul li:nth-child(2n) {
          margin: 0 0 15px; } }
      .theory .sec--theoryList ul li:nth-child(4) .ico img {
        margin: 0 0 0 auto; }
      .theory .sec--theoryList ul li:nth-child(3n) {
        margin: 0 0 30px; }
      @media screen and (max-width: 769px) {
        .theory .sec--theoryList ul li:nth-child(3) {
          margin: 0 4% 15px 0; } }
      .theory .sec--theoryList ul li .ico {
        display: flex;
        align-items: center;
        min-height: 160px;
        margin: 0 0 10px; }
        .theory .sec--theoryList ul li .ico img {
          display: block;
          margin: 0 auto; }
      .theory .sec--theoryList ul li p {
        font-size: 15px;
        line-height: 1.74; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryList ul li p {
            font-size: 14px; } }
  .theory .sec--theoryReason > .contentTtl {
    margin: 0 0 60px; }
    @media screen and (max-width: 769px) {
      .theory .sec--theoryReason > .contentTtl {
        margin: 0 0 30px; } }
  .theory .sec--theoryReason > p {
    margin: 0 0 20px; }
  .theory .sec--theoryReason .reason {
    display: flex;
    justify-content: space-between;
    margin: 0 0 100px; }
    @media screen and (max-width: 769px) {
      .theory .sec--theoryReason .reason {
        display: block;
        margin: 0 0 50px; } }
    .theory .sec--theoryReason .reason li {
      max-width: 380px;
      border: 8px solid #E8EAE8;
      padding: 20px 30px;
      box-sizing: border-box;
      align-items: center; }
      @media screen and (max-width: 769px) {
        .theory .sec--theoryReason .reason li {
          width: 100%;
          padding: 15px 20px; } }
      @media screen and (max-width: 769px) {
        .theory .sec--theoryReason .reason li:first-child {
          margin: 0 0 20px; } }
      .theory .sec--theoryReason .reason li p {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.75; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryReason .reason li p {
            font-size: 15px; } }
  .theory .sec--theoryReason .answer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 80px; }
    @media screen and (max-width: 769px) {
      .theory .sec--theoryReason .answer {
        display: block;
        margin: 0 0 40px; } }
    .theory .sec--theoryReason .answer li {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .theory .sec--theoryReason .answer li:nth-child(1) {
        width: 50%; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryReason .answer li:nth-child(1) {
            width: 100%;
            margin: 0 0 20px; } }
      .theory .sec--theoryReason .answer li:nth-child(2) {
        width: 48%; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryReason .answer li:nth-child(2) {
            width: 100%; } }
      .theory .sec--theoryReason .answer li .num {
        color: #52BC56;
        display: inline-block;
        font-family: 'Norwester Regular';
        font-size: 120px;
        padding: 0 30px 0 0;
        margin: 0 10px 0 0;
        border-right: 1px solid #707070;
        line-height: .8; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryReason .answer li .num {
            font-size: 80px;
            padding: 0 20px 0 0;
            text-align: center;
            width: 70px;
            box-sizing: border-box; } }
      .theory .sec--theoryReason .answer li h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5; }
        @media screen and (max-width: 769px) {
          .theory .sec--theoryReason .answer li h4 {
            font-size: 15px;
            width: calc(100% - 70px); } }

.logic .kv {
  background: url(../img/logic/kv.jpg) no-repeat center;
  background-size: cover; }
  .logic .kv__ttl {
    line-height: 1.35; }

.logic .sec {
  max-width: 800px;
  margin: 0 auto; }
  .logic .sec.pb60 {
    padding: 0 0 60px; }
  @media screen and (max-width: 769px) {
    .logic .sec {
      width: calc(100% - 9.37vw); } }
  .logic .sec .upperLetter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 100px; }
    @media screen and (max-width: 769px) {
      .logic .sec .upperLetter {
        display: block;
        margin: 0 0 50px; } }
    .logic .sec .upperLetter__left {
      margin: 0 4% 0 0; }
      @media screen and (max-width: 769px) {
        .logic .sec .upperLetter__left {
          margin: 0 0 20px; } }
    .logic .sec .upperLetter ul {
      width: 48%; }
      @media screen and (max-width: 769px) {
        .logic .sec .upperLetter ul {
          width: 100%; } }
      .logic .sec .upperLetter ul li {
        display: flex;
        align-items: center;
        margin: 0 0 40px; }
        @media screen and (max-width: 769px) {
          .logic .sec .upperLetter ul li {
            margin: 0 0 20px; } }
        .logic .sec .upperLetter ul li:last-child {
          margin: 0; }
        .logic .sec .upperLetter ul li .num {
          color: #52BC56;
          display: inline-block;
          font-family: 'Norwester Regular';
          font-size: 50px;
          padding: 5px 30px 5px 0;
          margin: 0 10px 0 0;
          border-right: 1px solid #707070;
          line-height: .8;
          width: 30px; }
          @media screen and (max-width: 769px) {
            .logic .sec .upperLetter ul li .num {
              font-size: 40px;
              padding: 5px 20px 5px 0; } }
        .logic .sec .upperLetter ul li h4 {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          width: calc(100% - 71px); }
          @media screen and (max-width: 769px) {
            .logic .sec .upperLetter ul li h4 {
              font-size: 15px; } }
  .logic .sec .lowerLetter {
    margin: 0 0 50px; }
    @media screen and (max-width: 769px) {
      .logic .sec .lowerLetter {
        margin: 0 0 30px; } }
    .logic .sec .lowerLetter li {
      display: flex;
      align-items: center;
      margin: 0 0 40px; }
      @media screen and (max-width: 769px) {
        .logic .sec .lowerLetter li {
          margin: 0 0 20px; } }
      .logic .sec .lowerLetter li:last-child {
        margin: 0; }
      .logic .sec .lowerLetter li .num {
        color: #52BC56;
        display: inline-block;
        font-family: 'Norwester Regular';
        font-size: 40px;
        padding: 5px 20px 5px 0;
        margin: 0 10px 0 0;
        border-right: 1px solid #707070;
        line-height: .8;
        width: 40px;
        text-align: center; }
        @media screen and (max-width: 769px) {
          .logic .sec .lowerLetter li .num {
            font-size: 30px;
            padding: 5px 20px 5px 0;
            width: 30px; } }
      .logic .sec .lowerLetter li h4 {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.5;
        width: calc(100% - 71px); }
        @media screen and (max-width: 769px) {
          .logic .sec .lowerLetter li h4 {
            font-size: 12px; } }
  .logic .sec .logic02--01 {
    max-width: 556px;
    margin: 0 auto; }
  .logic .sec .logic02--02 {
    max-width: 476px;
    margin: 0 auto; }
  .logic .sec .logic02--03 {
    margin: 30px 0 0; }
  .logic .sec .rightBox--logic01 .txtBox {
    width: calc(100% - 416px); }
    @media screen and (max-width: 769px) {
      .logic .sec .rightBox--logic01 .txtBox {
        width: 100%; } }
  .logic .sec .rightBox--logic01 .imgBox {
    width: 316px; }
    @media screen and (max-width: 769px) {
      .logic .sec .rightBox--logic01 .imgBox {
        width: 100%; } }

.training .kv {
  background: url(../img/training/kv.jpg) no-repeat center;
  background-size: cover; }
  .training .kv__ttl {
    line-height: 1.35; }

.training .sec {
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 769px) {
    .training .sec {
      width: calc(100% - 9.37vw); } }
  .training .sec .numList li {
    display: flex;
    align-items: center;
    margin: 0 0 40px; }
    @media screen and (max-width: 769px) {
      .training .sec .numList li {
        margin: 0 0 20px; } }
    .training .sec .numList li:last-child {
      margin: 0; }
    .training .sec .numList li .num {
      color: #52BC56;
      display: inline-block;
      font-family: 'Norwester Regular';
      font-size: 40px;
      padding: 5px 20px 5px 0;
      margin: 0 10px 0 0;
      border-right: 1px solid #707070;
      line-height: .8;
      width: 40px;
      text-align: center; }
      @media screen and (max-width: 769px) {
        .training .sec .numList li .num {
          font-size: 30px;
          padding: 5px 20px 5px 0;
          width: 30px; } }
    .training .sec .numList li span {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.5;
      width: calc(100% - 71px); }
      @media screen and (max-width: 769px) {
        .training .sec .numList li span {
          font-size: 12px; } }

.putting .kv {
  background: url(../img/putting/kv.jpg) no-repeat center;
  background-size: cover; }
  .putting .kv__ttl {
    line-height: 1.35; }

.putting .sec {
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 769px) {
    .putting .sec {
      width: calc(100% - 9.37vw); } }
  .putting .sec .numListBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media screen and (max-width: 769px) {
      .putting .sec .numListBox {
        display: block; } }
  .putting .sec .numList--left {
    width: 48%;
    margin: 0 4% 0 0; }
    @media screen and (max-width: 769px) {
      .putting .sec .numList--left {
        width: 100%;
        margin: 0 0 10px; } }
  .putting .sec .numList--right {
    width: 48%; }
    @media screen and (max-width: 769px) {
      .putting .sec .numList--right {
        width: 100%; } }
  .putting .sec .numList li {
    display: flex;
    align-items: center;
    margin: 0 0 40px; }
    @media screen and (max-width: 769px) {
      .putting .sec .numList li {
        margin: 0 0 20px; } }
    .putting .sec .numList li:last-child {
      margin: 0; }
    .putting .sec .numList li .num {
      color: #52BC56;
      display: inline-block;
      font-family: 'Norwester Regular';
      font-size: 40px;
      padding: 5px 20px 5px 0;
      margin: 0 10px 0 0;
      border-right: 1px solid #707070;
      line-height: .8;
      width: 40px;
      text-align: center; }
      @media screen and (max-width: 769px) {
        .putting .sec .numList li .num {
          font-size: 30px;
          padding: 5px 20px 5px 0;
          width: 30px; } }
    .putting .sec .numList li span {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.5;
      width: calc(100% - 71px); }
      @media screen and (max-width: 769px) {
        .putting .sec .numList li span {
          font-size: 12px; } }
  .putting .sec--textBox .rightBox {
    margin: 0 0 60px; }
    .putting .sec--textBox .rightBox .txtBox {
      width: calc(100% - 438px); }
      @media screen and (max-width: 769px) {
        .putting .sec--textBox .rightBox .txtBox {
          width: 100%;
          margin: 0 0 20px; } }
    .putting .sec--textBox .rightBox .imgBox {
      width: 408px; }
      @media screen and (max-width: 769px) {
        .putting .sec--textBox .rightBox .imgBox {
          width: 100%; } }
  .putting .sec.panelBox {
    max-width: 1204px;
    padding: 0 60px 128px; }
    @media screen and (max-width: 769px) {
      .putting .sec.panelBox {
        width: calc(100% - 9.37vw);
        padding: 0 0 64px; } }
    .putting .sec.panelBox .panelList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media screen and (max-width: 769px) {
        .putting .sec.panelBox .panelList {
          display: block; } }
      .putting .sec.panelBox .panelList .panelList__item {
        border: 1px solid #707070;
        box-sizing: border-box;
        width: 32%;
        margin: 0 0 40px;
        padding: 24px 24px 130px;
        position: relative; }
        @media screen and (max-width: 769px) {
          .putting .sec.panelBox .panelList .panelList__item {
            width: 100%;
            padding: 2em 1em;
            margin: 0 0 20px; } }
        .putting .sec.panelBox .panelList .panelList__item h4 {
          font-size: 24px;
          line-height: 1.7;
          margin: 0 0 40px;
          padding: 0 0 0 20px;
          position: relative;
          min-height: 80px; }
          @media screen and (max-width: 769px) {
            .putting .sec.panelBox .panelList .panelList__item h4 {
              margin: 0 0 20px;
              padding: 0 0 0 14px;
              font-size: 16px;
              min-height: inherit; } }
          .putting .sec.panelBox .panelList .panelList__item h4:before {
            content: "";
            background: #52BC56;
            display: block;
            width: 4px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 8px; }
            @media screen and (max-width: 769px) {
              .putting .sec.panelBox .panelList .panelList__item h4:before {
                width: 3px;
                height: 16px;
                top: 4px; } }
        @media screen and (max-width: 769px) {
          .putting .sec.panelBox .panelList .panelList__item .checkList {
            margin: 0 0 20px; } }
        .putting .sec.panelBox .panelList .panelList__item .checkList li {
          margin: 0 0 20px; }
          @media screen and (max-width: 769px) {
            .putting .sec.panelBox .panelList .panelList__item .checkList li {
              margin: 0 0 10px; } }
          .putting .sec.panelBox .panelList .panelList__item .checkList li:last-child {
            margin: 0; }
          .putting .sec.panelBox .panelList .panelList__item .checkList li p {
            color: #52BC56;
            font-size: 21px;
            font-weight: 600;
            position: relative;
            padding: 0 0 0 36px; }
            @media screen and (max-width: 769px) {
              .putting .sec.panelBox .panelList .panelList__item .checkList li p {
                font-size: 16px; } }
            .putting .sec.panelBox .panelList .panelList__item .checkList li p span {
              background: linear-gradient(transparent 40%, #FCFC0A 40%); }
            .putting .sec.panelBox .panelList .panelList__item .checkList li p:before {
              content: "";
              display: block;
              border-left: 4px solid #FF5259;
              border-bottom: 4px solid #FF5259;
              width: 20px;
              height: 10px;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              left: 0;
              top: 7px;
              position: absolute; }
              @media screen and (max-width: 769px) {
                .putting .sec.panelBox .panelList .panelList__item .checkList li p:before {
                  width: 16px;
                  height: 8px;
                  top: 6px; } }
        .putting .sec.panelBox .panelList .panelList__item .moreBtn {
          background: #52BC56;
          border-radius: 4px;
          color: #FFF;
          display: block;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          width: 260px;
          height: 64px;
          line-height: 64px;
          position: absolute;
          left: 50%;
          bottom: 24px;
          margin: 0 0 0 -130px; }
          @media screen and (max-width: 769px) {
            .putting .sec.panelBox .panelList .panelList__item .moreBtn {
              position: relative;
              left: inherit;
              bottom: inherit;
              margin: 0 auto;
              width: 80%;
              height: 40px;
              line-height: 40px;
              font-size: 16px; } }

.putting .modal-mask {
  background: rgba(0, 0, 0, 0.6);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999; }

.putting .modal {
  background: #FFF;
  display: none;
  width: 100%;
  max-width: 960px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; }
  @media screen and (max-width: 769px) {
    .putting .modal {
      width: 90%; } }
  .putting .modal__inner {
    padding: 80px; }
    @media screen and (max-width: 769px) {
      .putting .modal__inner {
        padding: 2em; } }
    .putting .modal__inner h3 {
      font-size: 32px;
      line-height: 1.34;
      margin: 0 0 20px; }
      @media screen and (max-width: 769px) {
        .putting .modal__inner h3 {
          font-size: 20px; } }
    .putting .modal__inner h4 {
      font-size: 24px;
      line-height: 1.7;
      margin: 0 0 10px;
      padding: 0 0 0 20px;
      position: relative; }
      @media screen and (max-width: 769px) {
        .putting .modal__inner h4 {
          padding: 0 0 0 14px;
          font-size: 16px; } }
      .putting .modal__inner h4:before {
        content: "";
        background: #52BC56;
        display: block;
        width: 4px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 8px; }
        @media screen and (max-width: 769px) {
          .putting .modal__inner h4:before {
            width: 3px;
            height: 16px;
            top: 4px; } }
    .putting .modal__inner .checkList {
      margin: 0 0 30px; }
      @media screen and (max-width: 769px) {
        .putting .modal__inner .checkList {
          margin: 0 0 20px; } }
      .putting .modal__inner .checkList li {
        margin: 0; }
        @media screen and (max-width: 769px) {
          .putting .modal__inner .checkList li {
            margin: 0 0 5px; } }
        .putting .modal__inner .checkList li:last-child {
          margin: 0; }
        .putting .modal__inner .checkList li p {
          color: #52BC56;
          font-size: 25px;
          font-weight: 600;
          position: relative;
          padding: 0 0 0 36px; }
          @media screen and (max-width: 769px) {
            .putting .modal__inner .checkList li p {
              font-size: 16px; } }
          .putting .modal__inner .checkList li p span {
            background: linear-gradient(transparent 40%, #FCFC0A 40%); }
          .putting .modal__inner .checkList li p:before {
            content: "";
            display: block;
            border-left: 4px solid #FF5259;
            border-bottom: 4px solid #FF5259;
            width: 20px;
            height: 10px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 0;
            top: 10px;
            position: absolute; }
            @media screen and (max-width: 769px) {
              .putting .modal__inner .checkList li p:before {
                width: 16px;
                height: 8px;
                top: 6px; } }
    .putting .modal__inner .imgBox ul {
      display: flex;
      justify-content: space-between; }
      .putting .modal__inner .imgBox ul li {
        width: 49%;
        margin: 0 2% 0 0; }
        .putting .modal__inner .imgBox ul li:last-child {
          margin: 0; }
        .putting .modal__inner .imgBox ul li img {
          display: block;
          width: 100%; }
    .putting .modal__inner > p {
      margin: 0 0 20px; }

.studio .kv {
  background: url(../img/studio/kv.jpg) no-repeat center;
  background-size: cover; }

.studio .sec {
  padding-bottom: 0; }
  .studio .sec__ttl {
    font-size: 32px;
    line-height: 1.6; }
    @media screen and (max-width: 769px) {
      .studio .sec__ttl {
        font-size: 24px; } }
  .studio .sec__s-ttl {
    position: relative;
    margin-bottom: 32px;
    padding-left: 16px;
    font-size: 24px;
    line-height: 1.4;
    border-left: 4px solid #52BC56; }
    @media screen and (max-width: 769px) {
      .studio .sec__s-ttl {
        margin-bottom: 24px;
        font-size: 16px;
        padding-left: 8px; } }

.studio .lead {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 769px) {
    .studio .lead {
      display: block; } }
  .studio .lead__textArea {
    width: 56.25%; }
    @media screen and (max-width: 769px) {
      .studio .lead__textArea {
        width: 100%; } }
  .studio .lead__ttl {
    margin-bottom: 30px; }
  .studio .lead__text {
    line-height: 1.86667; }
  .studio .lead__img {
    width: 38.75%;
    margin-left: 5%; }
    @media screen and (max-width: 769px) {
      .studio .lead__img {
        width: 100%;
        margin: 0; } }
    .studio .lead__img img {
      max-width: 100%; }

.studio .points {
  position: relative;
  padding-bottom: 120px;
  background-color: #EEEEEE; }
  @media screen and (max-width: 769px) {
    .studio .points {
      padding-bottom: 80px; } }
  .studio .points::before {
    position: absolute;
    right: 0;
    top: 40px;
    display: block;
    width: 90%;
    height: calc(100% - 80px);
    content: "";
    background-color: #FFF; }
    @media screen and (max-width: 769px) {
      .studio .points::before {
        left: 50%;
        right: inherit;
        transform: translateX(-50%); } }

.studio .point {
  position: relative;
  z-index: 1;
  width: 800px;
  margin: 0 auto;
  padding-top: 120px; }
  @media screen and (max-width: 769px) {
    .studio .point {
      width: calc(100% - 9.37vw);
      padding: 80px 24px 0;
      box-sizing: border-box; } }
  .studio .point__list {
    counter-reset: num;
    font-weight: bold;
    font-size: 15px;
    line-height: 2; }
    .studio .point__list li {
      display: flex;
      align-items: center;
      margin-top: 30px; }
      .studio .point__list li::before {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 44px;
        margin-right: 32px;
        counter-increment: num;
        content: counter(num, decimal-leading-zero);
        font-family: 'Norwester Regular';
        font-size: 40px;
        line-height: 1;
        color: #52BC56; }
        @media screen and (max-width: 769px) {
          .studio .point__list li::before {
            flex: 0 0 32px;
            margin-right: 24px;
            font-size: 32px; } }
      .studio .point__list li p {
        position: relative;
        padding-left: 24px;
        border-left: solid 2px #333; }
        .studio .point__list li p::before, .studio .point__list li p::after {
          position: absolute;
          left: -16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 16px 7px 0;
          border-color: transparent #333 transparent transparent; }
        .studio .point__list li p::after {
          left: -11px;
          border-color: transparent #FFF transparent transparent; }
        .studio .point__list li p span {
          background: linear-gradient(transparent 40%, #FCFC0A 40%); }

.studio .staff {
  width: 800px;
  margin: 80px auto 0; }
  @media screen and (max-width: 769px) {
    .studio .staff {
      width: calc(100% - 9.37vw); } }

.studio .staff-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 64px; }
  @media screen and (max-width: 769px) {
    .studio .staff-block {
      flex-direction: column-reverse; }
      .studio .staff-block:last-child {
        padding-bottom: 0; } }
  @media screen and (min-width: 770px) {
    .studio .staff-block:nth-child(odd) {
      flex-direction: row-reverse; } }
  .studio .staff-block__img {
    width: 41.25%; }
    @media screen and (max-width: 769px) {
      .studio .staff-block__img {
        width: 50%;
        max-width: 330px;
        margin: 0 auto 24px; } }
    .studio .staff-block__img img {
      width: 100%; }
  .studio .staff-block__textArea {
    width: 51.75%; }
    @media screen and (max-width: 769px) {
      .studio .staff-block__textArea {
        width: 100%; } }
  .studio .staff-block .profile__name {
    font-size: 18px; }
    @media screen and (max-width: 769px) {
      .studio .staff-block .profile__name {
        font-size: 14px; } }
  .studio .staff-block .profile__text {
    margin-top: 24px;
    font-size: 13px;
    line-height: 1.76923; }
    @media screen and (max-width: 769px) {
      .studio .staff-block .profile__text {
        margin-top: 16px;
        font-size: 11px; } }
  .studio .staff-block .comment {
    margin-top: 20px;
    padding: 24px 16px;
    background-color: #F2F2F2;
    font-size: 13px;
    line-height: 1.76923; }
    @media screen and (max-width: 769px) {
      .studio .staff-block .comment {
        margin-top: 16px;
        padding: 16px;
        font-size: 11px; } }

.studio .price {
  width: 800px;
  margin: 80px auto 0;
  padding-bottom: 160px; }
  @media screen and (max-width: 769px) {
    .studio .price {
      width: calc(100% - 9.37vw); } }
  .studio .price .btn {
    max-width: 415px;
    margin: 60px auto 0; }
    @media screen and (max-width: 769px) {
      .studio .price .btn {
        margin-top: 40px; } }
  .studio .price .more__child {
    max-width: 415px; }

.studio .price-cont:not(:first-child) {
  margin-top: 40px; }

.studio .price-cont .sec__s-ttl {
  margin-bottom: 15px; }

.studio .price-blockArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.studio .price-block {
  display: flex;
  flex-direction: column;
  width: 32.25%;
  margin: 5px 0;
  padding: 16px;
  font-size: 11px;
  box-sizing: border-box;
  background-color: #F2F2F2;
  border: solid 1px #E8EAE8; }
  .studio .price-block:empty {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none; }
  @media screen and (max-width: 769px) {
    .studio .price-block {
      width: 100%;
      font-size: 12px; } }
  .studio .price-block__ttl {
    font-weight: bold;
    line-height: 1.72727; }
  .studio .price-block__text {
    font-size: 11px; }
  .studio .price-block__price {
    margin-top: auto;
    padding-top: 10px;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
